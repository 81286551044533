import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Snackbar,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import user from '../../images/user.png';
import { useDispatch, useSelector } from 'react-redux';
import { encryptId } from '../utils/encryptDecrypt';
import PATH from '../utils/path';
import { useNavigate } from 'react-router-dom';
import FilterSupplier from '../utils/FilterSupplier';
import { fetchSupplierWithSearch, fetchSupplier } from '../../features/supplierSlice';
import Loader from '../utils/loader';
import AlertDialog from '../utils/AlertDialog';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';
import SelectClaimSupplierProduct from './ClaimSupplierProduct';
import CommonDialog from '../utils/CommonDialog';
import { fetchSupplierProduct } from '../../features/supplierProductSlice';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';

export default function SelectClaimSupplier({ claimSupplier, setClaimSupplier, claimId, handlecreateClaim, supplierProducts, setSupplierProducts }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [openAlertChainDialog, setOpenAlertChainDialog] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const { suppliers, loading } = useSelector((state) => state.supplier);
  const itemsPerPage = 12;
  const [currentPageData, setCurrentPageData] = useState([]);
  const pageCount = Math.ceil((suppliers?.totalRecords || 0) / itemsPerPage);
  const dispatch = useDispatch();
  const [openCreateNewDialog, setOpenCreateNewDialog] = useState(false);
  const [supplierId, setSupplierId] = useState('');
  const [supplierName, setSupplierName] = useState('');

  useEffect(() => {
    if (suppliers?.currentRecords === suppliers?.totalRecords) {
      const currentPageData = suppliers?.suppliers?.slice((page - 1) * itemsPerPage, page * itemsPerPage);
      setCurrentPageData(currentPageData);
    } else {
      setCurrentPageData(suppliers.suppliers);
    }
  }, [suppliers, page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(fetchSupplier(claimId));
  }, [dispatch]);

  const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      border: '1px solid #dadde9',
    },
  }));

  const toggleSupplierSelection = (selectedSupplier, cncl) => {
    if (!checkOnlineStatus()) return;
    setClaimSupplier((prevData = []) => {
      const isSelected = prevData?.some((supplier) => supplier.supplierId === selectedSupplier?.id);

      if (isSelected && cncl) {
        return prevData.filter((supplier) => supplier.supplierId !== selectedSupplier?.id);
      } else {
        return [
          ...prevData,
          {
            supplierId: selectedSupplier?.id,
            name: selectedSupplier?.name,
          },
        ];
      }
    });
  };

  const handleSearchChange = (e) => {
    if (!checkOnlineStatus()) return;
    setSearchTerm(e.target.value);
    let term = e.target.value;
    dispatch(fetchSupplierWithSearch({ term }));
  };

  const selectAllSuppliers = () => {
    if (!checkOnlineStatus()) return;
    if (claimSupplier?.length === suppliers.suppliers?.length) {
      setClaimSupplier([]);
    } else {
      const allSuppliers = suppliers.suppliers?.map((supplier) => ({
        supplierId: supplier.id,
        name: supplier?.name,
      }));
      setClaimSupplier(allSuppliers);
    }
  };

  const handleRemoveSelectedSupplier = () => {
    if (!checkOnlineStatus()) return;
    setClaimSupplier([]);
    setOpenAlertChainDialog(false);
  };
  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleClose = () => {
    setOpenCreateNewDialog(false);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <TextField
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                  },
                }}
                variant="outlined"
                placeholder={t('search')}
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FilterSupplier page={page} setPage={setPage} itemsPerPage={itemsPerPage} claimId={claimId} showselected={true} />
            </Box>
            <Pagination
              sx={{
                '& .MuiPaginationItem-root': {
                  color: 'gray',
                },
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: '#EC895A',
                  color: '#fff',
                },
              }}
              count={pageCount}
              page={page}
              onChange={handleChange}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="50%" mt={1} gap={2}>
            <Button
              onClick={selectAllSuppliers}
              sx={{
                bgcolor: '#101010',
                color: '#fff',
                ':hover': { bgcolor: '#0a0a0a' },
                borderRadius: '20px',
                height: '40px',
                width: '120px',
                textAlign: 'center',
                textTransform: 'capitalize',
              }}
            >
              {claimSupplier?.length === suppliers.suppliers?.length ? t('deselect_all') : t('select_all')}
            </Button>
            <Button
              sx={{
                bgcolor: 'gray',
                ':hover': { bgcolor: 'gray' },
                color: '#fff',
                borderRadius: '20px',
                height: '40px',
                width: '120px',
                textAlign: 'center',
                textTransform: 'capitalize',
                cursor: 'none',
              }}
            >
              {t('selected')}:{loading ? <CircularProgress size={'1rem'} sx={{ color: 'blue', marginLeft: '5px' }} /> : claimSupplier?.length || 0}
            </Button>
            <Button
              disabled={claimSupplier?.length <= 0 || claimSupplier?.length === suppliers.suppliers?.length}
              onClick={() => setOpenAlertChainDialog(true)}
              sx={{
                bgcolor: claimSupplier?.length <= 0 || claimSupplier?.length === suppliers.suppliers?.length ? 'gray' : '#000',
                ':hover': { bgcolor: '#101010' },
                color: '#fff',
                borderRadius: '20px',
                height: '40px',
                width: '120px',
                textAlign: 'center',
                textTransform: 'capitalize',
              }}
            >
              {t('Unselect_All')}
            </Button>
          </Box>
          {loading ? (
            <Loader />
          ) : (
            <Grid mt={1} container spacing={2}>
              {currentPageData?.length > 0 ? (
                currentPageData.map((supplier) => {
                  const isSelected = claimSupplier?.some((s) => s.supplierId === supplier.id);
                  const cardContent = (
                    <CardContent
                      sx={{
                        textAlign: 'center',
                        height: '270px',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        cursor: !claimSupplier.find((s) => s.supplierId === supplier.id) ? 'pointer' : '',
                      }}
                      onClick={() => {
                        !claimSupplier.find((s) => s.supplierId === supplier.id) && toggleSupplierSelection(supplier);
                      }}
                    >
                      <img
                        src={supplier?.urls ? supplier?.urls[0]?.url : user}
                        alt=""
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: '30px',
                          border: '1px solid gray',
                        }}
                      />
                      <Checkbox
                        sx={{ position: 'absolute', top: '0px', right: '0px' }}
                        icon={<span />}
                        checkedIcon={<CancelIcon color="error" />}
                        checked={isSelected}
                        onChange={(e) => {
                          toggleSupplierSelection(supplier, e.target.checked ? null : 'remove');
                        }}
                      />
                      <Typography variant="body2" sx={{ color: '#000', fontWeight: 600 }}>
                        {supplier?.name}
                      </Typography>
                      <Typography sx={{ color: '#0e1090', fontWeight: 500, fontSize: '14px' }}>{supplier?.vanityName?.value}</Typography>
                      <Typography sx={{ color: '#000', fontSize: '12px', fontWeight: 700 }}>
                        <span style={{ color: 'gray' }}>{t('vat')}</span> {supplier?.vat}
                      </Typography>
                      <Typography sx={{ color: 'gray', fontSize: '12px' }}>
                        <LocationOnIcon sx={{ fontSize: '16px', color: '#000' }} />
                        {supplier?.address?.street} {supplier?.address?.city} {supplier?.address?.state} {supplier?.address?.country}
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, marginTop: '10px' }}>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSupplierId(supplier?.id);
                            dispatch(fetchSupplierProduct({ supplierId: supplier.id, page: 1 }));
                            setOpenCreateNewDialog(true);
                            setSupplierName(supplier?.name);
                          }}
                          sx={{
                            fontSize: '10px',
                            bgcolor: 'gray',
                            color: '#fff',
                            borderRadius: '100px',
                            textTransform: 'none',
                            ':hover': { bgcolor: '#101010' },
                          }}
                          disabled={supplier?.numberOfProducts <= 0}
                        >
                          {`${supplier?.numberOfProducts} ${t('view_product')}`}
                        </Button>
                        <Checkbox
                          sx={{ cursor: 'default' }}
                          icon={<span />}
                          checkedIcon={<CheckCircleIcon color="success" />}
                          checked={!!supplier?.isProductsHasClaims || (supplierProducts?.some((ele) => ele?.supplierId === supplier?.id) ?? false)}
                          disableRipple
                          inputProps={{ readOnly: true }}
                        />
                      </Box>
                    </CardContent>
                  );
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={supplier.id}>
                      {isSelected ? (
                        <Card>{cardContent}</Card>
                      ) : (
                        <CustomTooltip title={t('select_supplier')} placement="top" arrow>
                          <Card>{cardContent}</Card>
                        </CustomTooltip>
                      )}
                    </Grid>
                  );
                })
              ) : (
                <Grid item xs={12}>
                  <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: '14px' }}>{t('no_records_found')}</Typography>
                </Grid>
              )}
            </Grid>
          )}
        </CardContent>
      </Card>
      <CommonDialog open={openCreateNewDialog} handleClose={handleClose} heading={t('supplier_products')}>
        <DialogContent sx={{ width: '800px' }}>
          <SelectClaimSupplierProduct
            supplierId={supplierId}
            supplierProducts={supplierProducts || []}
            setSupplierProducts={setSupplierProducts}
            handleClose={handleClose}
            supplierName={supplierName}
          />
        </DialogContent>
      </CommonDialog>
      <AlertDialog
        btnName={t('yes')}
        title={t('remove_all_the_suppliers_connected_to_this_claim')}
        description={t('are_you_sure_you_want_to_remove_all_suppliers')}
        open={openAlertChainDialog}
        handleClick={handleRemoveSelectedSupplier}
        handleCloseDialog={() => {
          setOpenAlertChainDialog(false);
        }}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
}
