import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';
import { CircularProgress, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Pagination from '@mui/material/Pagination';
//import icons
import { IoMdArrowBack } from 'react-icons/io';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DEFAULT_IMAGE from '../../../images/default_product_image.png';
//relative path imports
import CommonInput from '../../utils/CommonInput';
import httpService from '../../../service/http.service';
import { RestUrlsConstants } from '../../../constants/rest-urls.constants';
import useCheckOnlineStatus from '../../../hooks/useOnlineCheckStatus';
import FilterProduct from '../../product_engine/FilterProduct';
import PATH from '../../utils/path';
import AddResultModal from './add_result_modal';

//create mui styled components
const StyledBackButton = styled(Button)(({ theme }) => ({
  bgcolor: 'none',
  color: '#000',
  textTransform: 'capitalize',
  px: theme.spacing(2),
  '&:hover': {
    bgcolor: 'none',
  },
}));

const StyledProductCard = styled(Paper)(({ theme, isSelected }) => ({
  borderRadius: theme.spacing(1),
  padding: '16px 8px',
  backgroundColor: '#EFEFEF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  flexDirection: 'column',
  textAlign: 'center',
  cursor: 'pointer',
  outline: isSelected && `1px solid #0E1090`,
  minHeight: '100%',
}));

const StyledNextBtn = styled(Button)(({}) => ({
  backgroundColor: '#0E1090',
  padding: '12px 16px',
  borderRadius: '100px',
  textTransform: 'capitalize',
  fontWeight: '700',
  color: '#ffffff',
  '&:hover': { backgroundColor: '#0E1090' },
}));

const StyledProductDescText = styled(Typography)(({}) => ({
  color: '#545454',
  fontSize: '12px',
  fontWeight: '700',
}));

const AddResult = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [assessmentName, setAssessmentName] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isSelected, setIsSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let page = currentPage;
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [productCount, setProductCount] = useState();
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState(null);
  const [searchFilter, setSearchFilter] = useState();
  const [assessmentNameError, setAssessmentNameError] = useState('');
  const [openAddResultModal, setOpenAddResultModal] = useState(false);
  const [lcaData, setLcaData] = useState([]);

  useEffect(() => {
    if (searchValue) {
      handleSearchChange(searchValue, page, filters);
    } else {
      fetchProducts(page, filters, searchFilter);
    }
  }, [searchValue, page, currentPage]);

  useEffect(()=>{
    handleGetLcaData();
  },[])

  const fetchProducts = async (pageNo, filters, searchFilter) => {
    console.log('fetch products');
    if (!checkOnlineStatus()) return;
    setLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.product}?lca=false&page=${pageNo}&limit=10${filters ? `&${filters}` : ''}${searchValue ? `&query=${searchFilter}` : ''}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data) {
        // const filteredProducts = response?.data?.data?.products.filter((product) => !product.lcaId);
        setProducts(response?.data?.data?.products);
        setProductCount(response?.data?.data?.totalRecords);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = async (term, pageNo, filters) => {
    setSearchFilter(term);
    setLoading(true);
    try {
      const encodedTerm = encodeURIComponent(term); 
      const response = await httpService.get(`${RestUrlsConstants.product}?lca=false&query=${encodedTerm}&page=${pageNo}&limit=10${filters ? `&${filters}` : ''}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data) {
        setProducts(response?.data?.data?.products);
        setProductCount(response?.data?.data?.totalRecords);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterProductCollection = async (hasSupplyChain, hasCollection, isArchived, isQrId, hasLCA) => {
    setLoading(true);
    try {
      const collectionQuery = hasCollection ? `collection=${hasCollection}&` : '';
      const isArchivedQuery = isArchived && `isArchived=${isArchived === 'Yes' ? true : false}&`;
      const qrQuery = isQrId ?? `qr=${isQrId === 'Yes' ? true : false}`;
      const filterString = `${collectionQuery}${isArchivedQuery}${qrQuery}`;
      setFilters(filterString);

      const response = await httpService.get(`${RestUrlsConstants.product}?lca=false&${collectionQuery}${isArchivedQuery}${qrQuery}${searchValue ? `&query=${searchFilter}` : ''}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });

      if (response?.data?.status) {
        setProducts(response?.data?.data?.products);
        setProductCount(response?.data?.data?.totalRecords);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const totalPages = Math.ceil(productCount / 10);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSelection = (id) => {
    setIsSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleGetLcaData = async () => {
    if (!checkOnlineStatus()) return;
    try {
      const response = await httpService.get(RestUrlsConstants.LCA, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.status) {
        setLcaData(response?.data?.data)
        
      }
    } catch (error) {
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  }
  
  const handlNextBtn = () => {
    if (lcaData?.some((item) => item?.name === assessmentName)) {
      setSnackBar({ open: true, message: t('assessment_is_already_exists_with_this_name') });
    } else {
      setOpenAddResultModal(true);
    }
  }
  

  return (
    <Box mx={2}>
      <StyledBackButton startIcon={<IoMdArrowBack />} onClick={() => navigate(`${PATH.LIFECYCLEASSESSMENT}`)}>
        {t('back')}
      </StyledBackButton>
      <Box mt={1}>
        <CommonInput
          label={
            <Typography fontWeight={'700'} pb={1}>
              {t('assessment_name_label')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
          }
          placeholder={t('assessment_name_placeholder')}
          value={assessmentName}
          type="text"
          variant="standard"
          autoComplete={false}
          handleChange={(e) => {
            const value = e.target.value;
            const isValid = /^[a-zA-Z\s]{1,250}$/.test(value);
            if (isValid || value === '') {
              setAssessmentName(value);
              setAssessmentNameError('');
            } else {
              setAssessmentNameError('Assessment name must contain only alphabets and be less than 250 characters.');
            }
          }}
        />
        {assessmentNameError && (
          <Typography color="error" variant="caption">
            {assessmentNameError}
          </Typography>
        )}
      </Box>
      <Box mt={5}>
        <Card sx={{ borderRadius: '20px', px: isMobile ? 1 : 4 }}>
          <CardContent>
            <Stack direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'}>
              <Typography fontWeight={'700'}>{t('select_products')} </Typography>
              <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '20px' }}>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder={t('search')}
                  value={searchValue}
                  sx={{
                    borderRadius: '20px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '20px',
                    },
                  }}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <FilterProduct
                  currentPage={currentPage}
                  fetchProducts={fetchProducts}
                  handleFilterProductCollection={handleFilterProductCollection}
                  setFilters={setFilters}
                  filters={filters}
                  searchFilter={searchFilter}
                  isSupplyChain={false}
                  isLcaRequired={false}
                />
              </Box>
            </Stack>

            <Box mt={4}>
              {loading ? (
                <Box display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                  <CircularProgress />
                </Box>
              ) : (
                <Grid container columnSpacing={9} rowSpacing={4}>
                  {products?.map((ele, index) => {
                    return (
                      <Grid item key={index} xs={12} sm={6} md={4} lg={2.4}>
                        <StyledProductCard isSelected={isSelected.includes(ele?.id)} onClick={() => handleSelection(ele?.id)}>
                          <img src={ele?.urls?.[0] || DEFAULT_IMAGE} alt="product_img" width={140} height={140} style={{ objectFit: 'cover' }} />
                          {ele?.title && (
                            <Box sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              <Typography mt={1} fontWeight={'700'}>
                                {ele?.title?.value}
                              </Typography>
                            </Box>
                          )}
                          <Stack mt={1}>
                            {ele?.weight && ele?.weight?.value !== '' && ele.weight.value !== undefined && ele.weight.value !== null && (
                              <StyledProductDescText>
                                {t('weight')}&nbsp;:&nbsp;{ele?.weight?.value}&nbsp;{ele?.weight?.unit}
                              </StyledProductDescText>
                            )}
                            {ele?.category && ele?.category !== '' && ele.category !== undefined && ele.category !== null && (
                              <StyledProductDescText>
                                {t('category')}&nbsp;:&nbsp;{ele?.category}
                              </StyledProductDescText>
                            )}
                            {ele?.composition &&
                              ele?.composition?.en?.length > 0 &&
                              ele?.composition?.en[0]?.percentage !== '' &&
                              ele?.composition?.en[0]?.percentage !== undefined &&
                              ele?.composition?.en[0]?.percentage !== null && (
                                <StyledProductDescText>
                                  {t('composition')}&nbsp;:&nbsp;{ele?.composition?.en[0]?.percentage}
                                </StyledProductDescText>
                              )}
                            {ele?.qrId && ele?.qrId !== '' && ele.qrId !== undefined && ele.qrId !== null && (
                              <Box sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <StyledProductDescText>
                                  {t('sku')}&nbsp;:&nbsp;{ele?.qrId}
                                </StyledProductDescText>
                              </Box>
                            )}
                          </Stack>
                        </StyledProductCard>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Box>
          </CardContent>
          <Box my={1} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            {productCount > 0 && (
              <Pagination
                sx={{
                  '& .MuiPaginationItem-root': {
                    color: 'gray',
                  },
                  '& .MuiPaginationItem-root.Mui-selected': {
                    backgroundColor: '#EC895A',
                    color: '#fff',
                  },
                }}
                count={totalPages}
                page={page}
                onChange={handleChange}
              />
            )}
          </Box>
          <Box my={1} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {(products && products?.length == 0) && (
                <Typography color={'#808080'} fontSize={16} mt={1}>
                  {t('no_product_found')}
                </Typography>
            )}
          </Box>
        </Card>
      </Box>
      <Box mt={3}>
        <Card sx={{ px: isMobile ? 3 : 5, py: 4, borderRadius: '20px' }}>
          <Stack direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'} spacing={2}>
            <Box>
              <Typography fontWeight={'700'}>{t('select_product_and_continue')}</Typography>
              {(isSelected && isSelected?.length > 0) && (
                <Typography color={'#808080'} fontSize={12} mt={1}>
                  {`${t('selected_products')} ${isSelected?.length} ${t('product')}`}
                </Typography>
              )}
            </Box>
            <StyledNextBtn variant="contained" disabled={isSelected.length === 0 || !assessmentName.trim()} onClick={handlNextBtn}>
              {t('next')}
              <NavigateNextIcon />
            </StyledNextBtn>
          </Stack>
        </Card>
      </Box>
      {openAddResultModal && <AddResultModal assessmentName={assessmentName} productIds={isSelected} open={openAddResultModal} onClose={() => setOpenAddResultModal(false)} />}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Box>
  );
};

export default AddResult;
