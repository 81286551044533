// import * as React from 'react';
// import Popover from '@mui/material/Popover';
// import { IconButton, InputLabel } from '@mui/material';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import { Box, FormControl, MenuItem, Select, Typography, ListItemText, Button } from '@mui/material';
// import SortIcon from '@mui/icons-material/Sort';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import { useTranslation } from 'react-i18next';
// import {
//   fetchFilterProductsCollection,
//   fetchFilterProductsIsArchive,
//   fetchFilterProductsIsSelected,
//   fetchFilterProductsQRId,
//   fetchProducts,
//   fetchSortProducts,
//   fetchSupplierProductFilter,
// } from '../../features/productSlice';
// import { useDispatch } from 'react-redux';
// import httpService from '../../service/http.service';
// import { RestUrlsConstants } from '../../constants/rest-urls.constants';

// export default function FilterBrandProduct({ page, setPage, itemsPerPage, claimId, showSelected }) {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [sort, setSort] = React.useState('');
//   const [hasCollection, setHasCollection] = React.useState('');
//   const [isArchived, setIsArchived] = React.useState('');
//   const [isQrId, setIsQrId] = React.useState('');
//   const [collection, setCollection] = React.useState([]);
//   const [isSelected, setIsSelected] = React.useState('');
//   const [filterValues, setFilterValues] = React.useState({
//     hasCollection: '',
//     isArchived: '',
//     isSelected: '',
//     isQrId: '',
//     sortBy: '',
//   });
//   const handleFilterChange = (filterType, value) => {
//     console.log(filterType, value, '%%%%%%%%%%%%');

//     const updatedFilters = { ...filterValues, [filterType]: value };
//     setFilterValues(updatedFilters);
//     console.log(updatedFilters, '############');

//     dispatch(
//       fetchSupplierProductFilter({
//         page,
//         itemsPerPage,
//         filterValue: updatedFilters,
//         claimId,
//       })
//     );

//     handleClose();
//   };

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//     fetchCollectionList();
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const fetchCollectionList = async () => {
//     try {
//       const response = await httpService.get(`${RestUrlsConstants.product}/collection`, {
//         headers: {
//           Authorization: localStorage.getItem('Authorization'),
//         },
//       });
//       if (response?.data?.status) {
//         setCollection(response?.data?.data);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleClearAll = () => {
//     setFilterValues({
//       isArchived: '',
//       isSelected: '',
//       isQrId: '',
//       sortBy: '',
//     });
//     dispatch(fetchProducts({ page: 1, itemsPerPage }));
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? 'simple-popover' : undefined;

//   return (
//     <>
//       <IconButton onClick={handleClick} sx={{ bgcolor: '#0e1090', borderRadius: '30px', ':hover': { bgcolor: '#0e1090' } }}>
//         <FilterListIcon sx={{ color: '#fff' }} />
//       </IconButton>
//       <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//       >
//         <Box sx={{ width: 300, padding: 2, border: '1px solid #ddd', borderRadius: 1 }}>
//           <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
//             <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
//               {t('filter')} <FilterAltIcon sx={{ fontSize: '20px' }} />
//             </Typography>
//             <Button onClick={handleClearAll} sx={{ fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize', color: '#0e1090' }}>
//               {t('clear_all')}
//             </Button>
//           </Box>
//           <InputLabel sx={{ fontSize: '14px' }}>{t('collection')}</InputLabel>
//           <FormControl fullWidth sx={{ mb: 2 }}>
//             <Select variant="standard" size="small" value={filterValues.hasCollection} onChange={(value) => handleFilterChange('hasCollection', value)}>
//               {collection?.map((name) => (
//                 <MenuItem key={name} value={name} sx={{ fontSize: '12px' }}>
//                   <ListItemText primary={name} />
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           <InputLabel sx={{ fontSize: '14px' }}>{t('is_archived')}</InputLabel>
//           <FormControl fullWidth sx={{ mb: 2 }}>
//             <Select variant="standard" size="small" value={filterValues.isArchived} onChange={(e) => handleFilterChange('isArchived', e.target.value)}>
//               {[t('yes'), t('no')].map((option) => (
//                 <MenuItem key={option} value={option}>
//                   {option}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           {showSelected && (
//             <>
//               <InputLabel sx={{ fontSize: '14px' }}>{t('is_selected')}</InputLabel>
//               <FormControl fullWidth sx={{ mb: 2 }}>
//                 <Select variant="standard" size="small" value={filterValues.isSelected} onChange={(value) => handleFilterChange('isSelected', value)}>
//                   {[t('yes'), t('no')].map((option) => (
//                     <MenuItem key={option} value={option}>
//                       {option}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </>
//           )}
//           <InputLabel sx={{ fontSize: '14px' }}>{t('qr_code')}</InputLabel>
//           <FormControl fullWidth sx={{ mb: 2 }}>
//             <Select variant="standard" size="small" value={filterValues.isQrId} onChange={(value) => handleFilterChange('isQrId', value)}>
//               {[t('yes'), t('no')].map((country) => (
//                 <MenuItem key={country} value={country}>
//                   {country}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
//             {t('sort_by')} <SortIcon />
//           </Typography>
//           <FormControl fullWidth sx={{ mb: 2 }}>
//             <Select variant="standard" size="small" value={filterValues.sortBy} onChange={(e) => handleFilterChange('sortBy', e.target.value)}>
//               {[t('title_'), t('collectionName_'), t('date_')].map((sortOption) => (
//                 <MenuItem key={sortOption} value={sortOption} sx={{ textTransform: 'capitalize' }}>
//                   {sortOption}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Box>
//       </Popover>
//     </>
//   );
// }

import * as React from 'react';
import Popover from '@mui/material/Popover';
import { IconButton, InputLabel } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, FormControl, MenuItem, Select, Typography, ListItemText, Button } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchProducts, fetchSupplierProductFilter } from '../../features/productSlice';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';

export default function FilterBrandProduct({ page, setPage, itemsPerPage, claimId, showSelected }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [collection, setCollection] = React.useState([]);
  const [filterValues, setFilterValues] = React.useState({
    collection: '',
    isArchived: '',
    isSelected: '',
    qr: '',
    sortBy: '',
  });

  const handleFilterChange = (filterType, value) => {
    const updatedFilters = { ...filterValues, [filterType]: value };

    if (filterValues[filterType] !== value) {
      setPage(1);
    }

    setFilterValues(updatedFilters);
    handleClose();
  };

  React.useEffect(() => {
    const hasFilters = Object.values(filterValues).some(val => val !== '');

    if (hasFilters) {
      dispatch(
        fetchSupplierProductFilter({
          page,
          itemsPerPage,
          filterValue: filterValues,
          claimId,
        })
      );
    }
  }, [page, filterValues, dispatch, itemsPerPage, claimId]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    fetchCollectionList();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchCollectionList = async () => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.product}/collection`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data?.status) {
        setCollection(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching collection list:', error);
    }
  };

  const handleClearAll = () => {
    setFilterValues({
      collection: '',
      isArchived: '',
      isSelected: '',
      qr: '',
      sortBy: '',
    });

    dispatch(fetchProducts({ page, itemsPerPage }));
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick} sx={{ bgcolor: '#0e1090', borderRadius: '30px', ':hover': { bgcolor: '#0e1090' } }}>
        <FilterListIcon sx={{ color: '#fff' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300, padding: 2, border: '1px solid #ddd', borderRadius: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
              {t('filter')} <FilterAltIcon sx={{ fontSize: '20px' }} />
            </Typography>
            <Button onClick={handleClearAll} sx={{ fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize', color: '#0e1090' }}>
              {t('clear_all')}
            </Button>
          </Box>

          <InputLabel sx={{ fontSize: '14px' }}>{t('collection')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={filterValues.collection} onChange={(e) => handleFilterChange('collection', e.target.value)}>
              {collection?.map((name) => (
                <MenuItem key={name} value={name} sx={{ fontSize: '12px' }}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <InputLabel sx={{ fontSize: '14px' }}>{t('is_archived')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={filterValues.isArchived} onChange={(e) => handleFilterChange('isArchived', e.target.value)}>
              <MenuItem value={t('yes')}>{t('yes')}</MenuItem>
              <MenuItem value={t('no')}>{t('no')}</MenuItem>
            </Select>
          </FormControl>

          {showSelected && (
            <>
              <InputLabel sx={{ fontSize: '14px' }}>{t('is_selected')}</InputLabel>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <Select variant="standard" size="small" value={filterValues.isSelected} onChange={(e) => handleFilterChange('isSelected', e.target.value)}>
                  <MenuItem value={t('yes')}>{t('yes')}</MenuItem>
                  <MenuItem value={t('no')}>{t('no')}</MenuItem>
                </Select>
              </FormControl>
            </>
          )}

          <InputLabel sx={{ fontSize: '14px' }}>{t('qr_code')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={filterValues.qr} onChange={(e) => handleFilterChange('qr', e.target.value)}>
              <MenuItem value={t('yes')}>{t('yes')}</MenuItem>
              <MenuItem value={t('no')}>{t('no')}</MenuItem>
            </Select>
          </FormControl>

          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
            {t('sort_by')} <SortIcon />
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={filterValues.sortBy} onChange={(e) => handleFilterChange('sortBy', e.target.value)}>
              <MenuItem value="title">{t('title_')}</MenuItem>
              <MenuItem value="collectionName">{t('collectionName_')}</MenuItem>
              <MenuItem value="date">{t('date_')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Popover>
    </>
  );
}
