import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
//import icons
import DEFAULT_IMAGE from '../../../../images/default_product_image.png';
//relative path imports
import useCheckOnlineStatus from '../../../../hooks/useOnlineCheckStatus';
import httpService from '../../../../service/http.service';
import { RestUrlsConstants } from '../../../../constants/rest-urls.constants';
import PATH from '../../../utils/path';
import FilterLCA from '../FilterLca';
import moment from 'moment';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { useSelector } from 'react-redux';
import LcaCSVUploadModal from '../../view_result/upload_csv_modal';
import ProductResultsModal from '../../modal/product_result';

//create mui styled components
const StyledBox = styled(Typography)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? '#0E1090' : '#808080',
  padding: '6px 10px',
  color: '#fff',
  borderRadius: '4px',
  cursor: 'pointer',
}));

const StyledProductCard = styled(Paper)(({ theme, isSelected }) => ({
  borderRadius: theme.spacing(1),
  padding: '16px 8px',
  backgroundColor: '#EFEFEF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  flexDirection: 'column',
  textAlign: 'center',
  outline: isSelected && `1px solid #0E1090`,
  minHeight: '100%',
}));

const StyledProductDescText = styled(Typography)(({}) => ({
  color: '#545454',
  fontSize: '12px',
  fontWeight: '700',
}));

const StyledChip = styled(Box)(({ isBgColor }) => ({
  padding: '4px 8px',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: isBgColor || '#B92932',
}));

const ViewChip = styled(Box)(({}) => ({
  border: '1px solid black',
  display: 'flex',
  padding: '4px 8px',
  borderRadius: '10px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  cursor: 'pointer',
}));

const data = ['All', 'Draft', 'Pending', 'Completed'];

const statusMapping = {
  1: 'PENDING',
  2: 'SUBMITTED',
  3: 'COMPLETED',
};

const LcaProductList = ({ selectedTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedItems, setSelectedItems] = useState(selectedTab);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [lcaData, setLcaData] = useState([]);
  const [uploadFileModal, setIsUploadFileModal] = useState(false);
  const [isOpenProductModal, setIsProductModal] = useState(false);
  const [completeLcaId, setCompletedLcaId] = useState('');
  const [selectedLcaId, setSelectedLcaId] = useState('');
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const user = useSelector((store) => store.user);

  useEffect(() => {
    if (searchValue) {
      handleSearchChange(searchValue.trim(), selectedItems);
    } else {
      fetchAllLCA(selectedItems);
    }
  }, [searchValue, selectedItems]);

  const fetchAllLCA = async (statusIndex) => {
    if (!checkOnlineStatus()) return;
    setLoading(true);
    try {
      let query = '';
      if (statusIndex !== 0) {
        const mappedStatus = statusMapping[statusIndex] || data[statusIndex].toUpperCase();
        query = `?status=${mappedStatus}`;
      }
      const response = await httpService.get(`${RestUrlsConstants.LCA}${query}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data) {
        setLcaData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = async (term, statusIndex) => {
    setLoading(true);
    try {
      const encodedTerm = encodeURIComponent(term); 
      let query = '';
      if (statusIndex !== 0) {
        const mappedStatus = statusMapping[statusIndex] || data[statusIndex].toUpperCase();
        query = `?status=${mappedStatus}&`;
      }
      const searchQuery = encodedTerm && selectedItems === 0 ? `?query=${encodedTerm}` : `query=${encodedTerm}`;
      const response = await httpService.get(`${RestUrlsConstants.LCA}${query}${searchQuery}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data) {
        setLcaData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const handleFilterProductCollection = async (created) => {
    setLoading(true);
    try {
      let query = '';
      if (selectedItems !== 0) {
        const mappedStatus = statusMapping[selectedItems] || data[selectedItems].toUpperCase();
        query = `?status=${mappedStatus}&`;
      }
      const createdQuery = created && selectedItems === 0 ? `?sortBy=${created}` : `sortBy=${created}`;

      const response = await httpService.get(`${RestUrlsConstants.LCA}${query}${createdQuery}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });

      if (response?.data?.status) {
        setLcaData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const manageContinueLCABtn = async (ele) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.LCA}/${ele.id}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });

      if (response?.data) {
        if (response?.data?.data?.set) {
          navigate(`${PATH.STARTASSESSMENT}/${response?.data?.data?.productId}?set=${response?.data?.data?.set}`);
          const languages = ['en', 'it', 'fr', 'de'];
          const composition = response?.data?.data?.garment?.composition;
          localStorage.setItem('editDraftLcaInfo', JSON.stringify({
            ...response?.data?.data,
            garment: {
                ...response?.data?.data?.garment,
                composition: languages.reduce((acc, lang) => {
                    acc[lang] = composition;
                    return acc;
                }, {})
            }
        }));
        } else {
          navigate(`${PATH.STARTASSESSMENT}/${response?.data?.data?.productId}?set=1`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClickViewBtn = (lcaID) => {
    setCompletedLcaId(lcaID);
    setIsProductModal(true);
  };

  const manageUploadFile = (lcaID) => {
    setIsUploadFileModal(true);
    setSelectedLcaId(lcaID);
  };

  const closeResultCSVModal = () => {
    setIsUploadFileModal(false);
    setSelectedLcaId('');
  };

  return (
    <Box mt={5}>
      <Card sx={{ borderRadius: '20px', px: isMobile ? 1 : 4, py: 4 }}>
        <Stack width={'500px'} borderBottom={'2px solid #80808099'} pb={2} direction={'row'} spacing={2}>
          {data.map((ele, index) => {
            return (
              <StyledBox isSelected={selectedItems === index} onClick={() => setSelectedItems(index)}>
                {ele}
              </StyledBox>
            );
          })}
        </Stack>

        <Stack mt={4} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography fontWeight={'700'}>Search for assessment</Typography>
          <Stack direction={'row'} spacing={2}>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '20px', width: '300px' }}>
              <TextField
                size="small"
                variant="outlined"
                placeholder={t('search')}
                value={searchValue}
                sx={{
                  borderRadius: '20px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                  },
                }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <FilterLCA fetchAllLCA={fetchAllLCA} selectedItems={selectedItems} handleFilterProductCollection={handleFilterProductCollection} />
            </Box>
          </Stack>
        </Stack>

        <Box mt={4}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
              <CircularProgress />
            </Box>
          ) : lcaData?.length > 0 ? (
            <Grid container spacing={2}>
              {lcaData?.map((ele, index) => {
                return (
                  <Grid key={index} item lg={2} md={3} sm={12}>
                    {' '}
                    <StyledProductCard>
                      <img src={ele?.urls?.[0] || DEFAULT_IMAGE} alt="product_img" width={140} height={140} style={{ objectFit: 'cover' }} />
                      {ele?.name && (
                        <Typography mt={1} fontWeight={'700'} color={'#0E1090'}>
                          {ele?.name}
                        </Typography>
                      )}
                      {ele?.title && (
                        <Typography mt={1} fontWeight={'700'}>
                          {ele?.title}
                        </Typography>
                      )}
                      <Box mt={1}>
                        {ele?.weight?.value && ele?.weight?.unit && (
                          <StyledProductDescText>
                            {t('weight')}&nbsp;:&nbsp;{ele?.weight?.value} {ele?.weight?.unit}
                          </StyledProductDescText>
                        )}
                        {ele?.category && (
                          <StyledProductDescText>
                            {t('category')}&nbsp;:&nbsp;{ele?.category}
                          </StyledProductDescText>
                        )}
                        {ele?.created && (
                          <StyledProductDescText>
                            {t('created')}&nbsp;:&nbsp;{moment(ele?.created).format('DD-MM-YYYY')}
                          </StyledProductDescText>
                        )}
                      </Box>
                      <Stack mt={1} direction={'row'} alignItems={'center'} spacing={1}>
                        {ele?.status === 'PENDING' && (
                          <Stack direction={'row'} spacing={1}>
                            <StyledChip>
                              <Typography color={'#fff'} fontSize={10}>
                                Draft
                              </Typography>
                            </StyledChip>
                            <StyledChip onClick={() => manageContinueLCABtn(ele)} sx={{ cursor: 'pointer' }} isBgColor="#0E1090">
                              <Typography color={'#fff'} fontSize={10}>
                                {t('continue')}
                              </Typography>
                            </StyledChip>
                          </Stack>
                        )}
                        {ele?.status === 'SUBMITTED' && (
                          <Stack direction={'row'} spacing={1}>
                            <StyledChip>
                              <Typography color={'#fff'} fontSize={10}>
                                PENDING
                              </Typography>
                            </StyledChip>
                            {user?.userType === 'SYSTEM' && (
                              <StyledChip onClick={() => manageUploadFile(ele?.id)} sx={{ cursor: 'pointer' }} isBgColor="#0E1090">
                                <Typography color={'#fff'} fontSize={10}>
                                  {t('assign_result')}
                                </Typography>
                              </StyledChip>
                            )}
                          </Stack>
                        )}
                        {ele?.status === 'COMPLETED' && (
                          <>
                            <StyledChip isBgColor={'#388E3C'}>
                              <Typography color={'#fff'} fontSize={12}>
                                {t('completed')}
                              </Typography>
                            </StyledChip>
                            <ViewChip onClick={() => onClickViewBtn(ele.id)}>
                              <Typography fontSize={12}>{t('view')}</Typography>
                              <MdOutlineRemoveRedEye fontSize={14} />
                            </ViewChip>
                          </>
                        )}
                      </Stack>
                    </StyledProductCard>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
              <Typography variant="h6" color="textSecondary">
                No Data Found
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
      {isOpenProductModal && (
        <ProductResultsModal
          open={isOpenProductModal}
          completeLcaId={completeLcaId}
          onClose={() => {
            setIsProductModal(false);
            setCompletedLcaId('');
          }}
        />
      )}
      {uploadFileModal && <LcaCSVUploadModal selectedLcaId={selectedLcaId} open={uploadFileModal} onClose={closeResultCSVModal} />}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Box>
  );
};

export default LcaProductList;
