import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, Chip, Grid, IconButton, InputAdornment, InputLabel, Pagination, TextField, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LanguageSelector from '../utils/LanguageSelector';
import SearchIcon from '@mui/icons-material/Search';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import Loader from '../utils/loader';
import FilterBrandProduct from '../utils/FilterBrandProduct';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts, fetchSearchProducts } from '../../features/productSlice';
import ArticleIcon from '@mui/icons-material/Article';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CertificateStatus } from '../../constants/common.constants';
import { fetchGoalsCertificates } from '../../features/certificateSlice';
import AlertDialog from '../utils/AlertDialog';
import AutorenewIcon from '@mui/icons-material/Autorenew';

export default function CreateSupplyChain(props) {
  const {
    openCreateSupplyChainForm,
    handleCloseCreateSupplyChain,
    updateSupplyChain,
    handleCreateSupplyChain,
    supplyChainData,
    setSupplyChainData,
    errors,
    lang,
    setLang,
    isDescription,
    isName,
    id,
    setSelectedCertificates,
    selectedCertificates,
  } = props;
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [allSelected, setAllSelected] = useState(false);
  const [openAlertChainDialog, setOpenAlertChainDialog] = useState(false);
  const dispatch = useDispatch();
  const { products, productLoading } = useSelector((state) => state.productEngine);
  const [page, setPage] = useState(1);
  const itemsPerPage = 12;
  const pageCount = Math.ceil(products?.totalRecords / itemsPerPage);

  const scrollContainerRef = useRef(null);
  const { certificateGoals, loading } = useSelector((state) => state.certificatesGoals);
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    dispatch(fetchGoalsCertificates());
  }, [dispatch]);

  useEffect(() => {
    setCertificates(certificateGoals?.certifications);
  }, [certificateGoals]);
  const scroll = (direction) => {
    const { current } = scrollContainerRef;
    if (current) {
      const scrollAmount = 200;
      current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  function getFileExtension(url) {
    const extensionRegex = /\.([a-zA-Z0-9]+)(?=\?|$)/;
    const match = extensionRegex.exec(url);
    if (match) {
      const extension = match[1].toLowerCase();
      return extension === 'pdf' || extension === 'doc' || extension === 'docx' || extension === 'csv' || extension === 'ppt' || extension === 'pptx' || extension === 'txt' || extension === 'xlsx'
        ? extension
        : '';
    }
    return '';
  }

  const handleCertificateClick = (certificate) => {
    setSelectedCertificates((prevSelected) => {
      const isAlreadySelected = prevSelected.find((item) => item.id === certificate.id);
      let updatedSelectedCertificates;
      if (isAlreadySelected) {
        updatedSelectedCertificates = prevSelected.filter((item) => item.id !== certificate.id);
      } else {
        updatedSelectedCertificates = [...prevSelected, certificate];
      }

      setSupplyChainData((prevData) => ({
        ...prevData,
        certificates: updatedSelectedCertificates.map((item) => item.id),
      }));

      return updatedSelectedCertificates;
    });
  };
  const isSelected = (id) => selectedCertificates.some((item) => item.id === id);

  const [searchTermDoc, setSearchTermDoc] = useState('');
  const handleSearchChangeDocument = (e) => {
    const term = e.target.value;
    setSearchTermDoc(term);
    if (term.trim() === '') {
      setCertificates(certificateGoals?.certifications);
    } else {
      const filtered = certificateGoals?.certifications.filter((cert) => cert.name.toLowerCase().includes(term.trim().toLowerCase()));
      setCertificates(filtered);
    }
  };

  const isProductSelected = (val) => {
    return (supplyChainData?.brandProduct?.some((product) => product.id === val?.id) || (val?.supplierChainId && val?.supplierChainId !== id)) ?? false;
  };

  const currentPageData = [...(products?.products || [])]
    .sort((a, b) => {
      const isSelectedA = isProductSelected(a);
      const isSelectedB = isProductSelected(b);

      if (isSelectedA && !isSelectedB) return -1;
      if (!isSelectedA && isSelectedB) return 1;

      const isDisabledA = a?.supplierChainId && a?.supplierChainId !== id;
      const isDisabledB = b?.supplierChainId && b?.supplierChainId !== id;

      if (!isDisabledA && isDisabledB) return -1;
      if (isDisabledA && !isDisabledB) return 1;

      if (a.supplierChainId && !b.supplierChainId) return -1;
      if (!a.supplierChainId && b.supplierChainId) return 1;
      return 0;
    })
    .slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    if (products.products?.length > 0 && supplyChainData?.brandProduct?.length === products.products?.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [products, supplyChainData]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.trim() === '' && searchTerm) {
      dispatch(fetchProducts({ page, itemsPerPage }));
    } else {
      dispatch(fetchSearchProducts({ term }));
    }
  };

  const selectProduct = (e, val) => {
    const isAlreadySelected = supplyChainData.brandProduct.some((product) => product.id === val.id);

    if (isAlreadySelected) {
      setSupplyChainData((prevData) => ({
        ...prevData,
        brandProduct: prevData.brandProduct.filter((product) => product.id !== val.id),
      }));
    } else {
      setSupplyChainData((prevData) => ({
        ...prevData,
        brandProduct: [...prevData.brandProduct, { id: val.id, name: val?.title?.value }],
      }));
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSupplyChainData((prevData) => ({
        ...prevData,
        brandProduct: [],
      }));
      setAllSelected(false);
    } else {
      const allProductIds = products.products?.map((product) => ({ id: product.id, name: product.title.value }));
      setSupplyChainData((prevData) => ({
        ...prevData,
        brandProduct: allProductIds,
      }));
      setAllSelected(true);
    }
  };
  const handleRemoveSelectedProduct = () => {
    setSupplyChainData((prevData) => ({
      ...prevData,
      brandProduct: [],
    }));
    setOpenAlertChainDialog(false);
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const rotateAnimation = {
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  };

  return (
    <>
      <Dialog
        sx={{ zIndex: 100 }}
        maxWidth={'xxl'}
        open={openCreateSupplyChainForm}
        onClose={() => {
          handleCloseCreateSupplyChain();
        }}
      >
        <DialogContent sx={{ bgcolor: '#ffff', width: '800px', position: 'relative', py: 0, pb: 2 }}>
          <DialogTitle sx={{ color: '#0e1090', fontWeight: 'bold', pl: 0 }}>{updateSupplyChain ? t('edit_supply_chain') : t('create_supply_chain')}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleCloseCreateSupplyChain();
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'black',
            }}
          >
            <CloseIcon />
          </IconButton>
          <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer' }}>{t('name') + '*'}</InputLabel>
          <TextField
            fullWidth
            type="text"
            placeholder={isName ? '' : t('name')}
            variant="standard"
            value={supplyChainData?.name}
            onChange={(e) => setSupplyChainData({ ...supplyChainData, name: e.target.value })}
            error={!!errors.name}
            helperText={errors.name ? errors.name : t('name_eg')}
            InputProps={{
              startAdornment: updateSupplyChain && isName && (
                <InputAdornment position="start">
                  <AutorenewIcon
                    sx={{
                      fontSize: '24px',
                      color: '#0e1090',
                      ml: 1,
                      animation: 'rotate 1s linear infinite',
                      ...rotateAnimation,
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Box mt={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer' }}>{t('description')}</InputLabel>
            <LanguageSelector selectLang={lang} setLang={setLang} />
          </Box>
          <TextField
            fullWidth
            value={supplyChainData?.description}
            onChange={(e) => setSupplyChainData({ ...supplyChainData, description: e.target.value })}
            type="text"
            placeholder={isDescription ? '' : t('description')}
            variant="standard"
            InputProps={{
              startAdornment: updateSupplyChain && isDescription && (
                <InputAdornment position="start">
                  <AutorenewIcon
                    sx={{
                      fontSize: '24px',
                      color: '#0e1090',
                      ml: 1,
                      animation: 'rotate 1s linear infinite',
                      ...rotateAnimation,
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />

          {/* ============================================> */}
          <InputLabel sx={{ mt: 4, color: '#000', fontWeight: '600', fontSize: '16px' }}>{t('assign_document')}</InputLabel>
          <InputLabel sx={{ mt: 2, mb: 2, color: 'gray', fontWeight: '600', fontSize: '12px' }}>{t('document_attach')}</InputLabel>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: '10px' }}>
            {loading ? (
              <Loader />
            ) : selectedCertificates?.length > 0 ? (
              selectedCertificates?.map((item) => (
                <>
                  {(item?.urls?.toString().includes('.pdf') ||
                    item?.urls?.toString().includes('.doc') ||
                    item?.urls?.toString().includes('.docx') ||
                    item?.urls?.toString().includes('.csv') ||
                    item?.urls?.toString().includes('.txt') ||
                    item?.urls?.toString().includes('.xlsx') ||
                    item?.urls?.toString().includes('.ppt') ||
                    item?.urls?.toString().includes('.pptx')) && (
                    <Box minWidth={'100px'}>
                      <Box
                        sx={{
                          height: '120px',
                          width: '100px',
                          bgcolor: '#fff',
                          borderRadius: '8px',
                          textAlign: 'justify',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                          border: '1px solid #0e1090',
                          cursor: 'pointer',
                        }}
                      >
                        {item?.preview ? (
                          <img height={120} width={100} alt="preview-img" src={item?.preview} />
                        ) : (
                          <>
                            <ArticleIcon style={{ fontSize: '50px' }} />
                            <span style={{ fontSize: '14px', color: 'gray', textTransform: 'uppercase' }}>{getFileExtension(item?.urls[0])}</span>
                          </>
                        )}
                        <Chip
                          label={item?.verificationStatus === CertificateStatus.valid ? 'Valid' : item?.verificationStatus === CertificateStatus.notValid ? 'Not Valid' : 'Yet to be valid'}
                          color={item?.verificationStatus === CertificateStatus.valid ? 'success' : item?.verificationStatus === CertificateStatus.notValid ? 'error' : 'warning'}
                          sx={{
                            height: '20px',
                            color: '#fff',
                            position: 'absolute',
                            bottom: '3px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontSize: '10px',
                          }}
                        />
                        <CancelIcon onClick={() => handleCertificateClick(item)} style={{ color: '#0e1090', position: 'absolute', top: 0, right: 0, fontSize: '20px' }} />
                      </Box>
                      <Tooltip title={item?.name} placement="top">
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: '#000',
                            textAlign: 'center',
                            px: 1,
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            wordWrap: 'break-word',
                            width: '100px',
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Tooltip>
                    </Box>
                  )}
                  {(item?.urls?.toString().includes('.jpg') || item?.urls?.toString().includes('.png') || item?.urls?.toString().includes('.jpeg')) && (
                    <Box>
                      <Box sx={{ position: 'relative' }}>
                        <img src={item?.urls} alt="" height={'120px'} width={'100px'} style={{ borderRadius: '8px', border: '1px solid #0e1090' }} />
                        <Chip
                          label={item?.verificationStatus === CertificateStatus.valid ? 'Valid' : item?.verificationStatus === CertificateStatus.notValid ? 'Not Valid' : 'Yet to be valid'}
                          color={item?.verificationStatus === CertificateStatus.valid ? 'success' : item?.verificationStatus === CertificateStatus.notValid ? 'error' : 'warning'}
                          sx={{
                            height: '20px',
                            color: '#fff',
                            position: 'absolute',
                            bottom: '3px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontSize: '10px',
                          }}
                        />
                        <CancelIcon onClick={() => handleCertificateClick(item)} style={{ color: '#0e1090', position: 'absolute', top: 0, right: 0, fontSize: '20px' }} />
                      </Box>
                      <Tooltip title={item?.name} placement="top">
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: '#000',
                            textAlign: 'center',
                            px: 1,
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            wordWrap: 'break-word',
                            width: '100px',
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Tooltip>
                    </Box>
                  )}
                </>
              ))
            ) : (
              <Typography sx={{ textAlign: 'center', p: 2 }}>{t('no_certificate_found')}</Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4 }}>
            <InputLabel sx={{ color: 'gray', fontSize: '16px' }}>{t('document_already_uploaded')}</InputLabel>
            <TextField
              value={searchTermDoc}
              onChange={(e) => handleSearchChangeDocument(e)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '40px',
                },
              }}
              size="small"
              variant="outlined"
              placeholder={t('search')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {loading ? (
            <Loader />
          ) : certificates?.length > 0 ? (
            <Box maxWidth={'100%'} display="flex" alignItems="center" position={'relative'} p={0}>
              <ArrowBackIosIcon sx={{ position: 'absolute', left: 0, zIndex: 1000 }} onClick={() => scroll('left')} />
              <Box className="example" ref={scrollContainerRef} display="flex" overflow="auto" whiteSpace="nowrap" sx={{ width: '100%', mt: 2 }}>
                {certificates?.map((item, index) => (
                  <>
                    {(item?.urls?.toString().includes('.pdf') ||
                      item?.urls?.toString().includes('.doc') ||
                      item?.urls?.toString().includes('.docx') ||
                      item?.urls?.toString().includes('.csv') ||
                      item?.urls?.toString().includes('.txt') ||
                      item?.urls?.toString().includes('.ppt') ||
                      item?.urls?.toString().includes('.pptx') ||
                      item?.urls?.toString().includes('.xlsx')) && (
                      <Box mx={1} minWidth={'100px'}>
                        <Box
                          onClick={() => handleCertificateClick(item)}
                          sx={{
                            height: '120px',
                            width: '100px',
                            bgcolor: '#fff',
                            borderRadius: '8px',
                            textAlign: 'justify',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            border: isSelected(item.id) ? '2px solid #0e1090' : '1px solid gray',
                            cursor: 'pointer',
                          }}
                        >
                          {item?.preview ? (
                            <img height={120} width={100} alt="preview-img" src={item?.preview} />
                          ) : (
                            <>
                              <ArticleIcon style={{ fontSize: '50px' }} />
                              <span style={{ fontSize: '14px', color: 'gray', textTransform: 'uppercase' }}>{getFileExtension(item?.urls[0])}</span>
                            </>
                          )}
                          <CheckCircleIcon style={{ color: isSelected(item.id) ? '#0e1090' : 'gray', position: 'absolute', top: 0, right: 0, fontSize: '20px' }} />
                        </Box>
                        <Tooltip title={item?.name} placement="top">
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#000',
                              textAlign: 'center',
                              px: 1,
                              alignItems: 'center',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              wordWrap: 'break-word',
                              width: '100px',
                            }}
                          >
                            {item?.name}
                          </Typography>
                        </Tooltip>
                      </Box>
                    )}
                    {(item?.urls?.toString().includes('.jpg') || item?.urls?.toString().includes('.png') || item?.urls?.toString().includes('.jpeg')) && (
                      <Box mx={1} sx={{ minWidth: '100px' }}>
                        <Box sx={{ position: 'relative' }} onClick={() => handleCertificateClick(item)}>
                          <img
                            src={item?.urls[0]}
                            alt=""
                            height={'120px'}
                            width={'100px'}
                            style={{ borderRadius: '8px', border: isSelected(item.id) ? '2px solid #0e1090' : '1px solid gray', cursor: 'pointer' }}
                          />
                          <CheckCircleIcon style={{ color: isSelected(item.id) ? '#0e1090' : 'gray', position: 'absolute', top: 0, right: 0, fontSize: '20px' }} />
                        </Box>
                        <Tooltip title={item?.name} placement="top">
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#000',
                              textAlign: 'center',
                              px: 1,
                              alignItems: 'center',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              wordWrap: 'break-word',
                              width: '100px',
                            }}
                          >
                            {item?.name}
                          </Typography>
                        </Tooltip>
                      </Box>
                    )}
                  </>
                ))}
              </Box>
              <ArrowForwardIosIcon sx={{ position: 'absolute', right: 0, zIndex: 1000 }} onClick={() => scroll('right')} />
            </Box>
          ) : (
            <Typography sx={{ textAlign: 'center', p: 2 }}>{t('no_certificate_found')}</Typography>
          )}
          {/* ==================================================> */}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
            <Typography sx={{ color: '#0e1090', fontWeight: 'bold' }}>{t('select_products')}</Typography>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <TextField
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                  },
                }}
                variant="outlined"
                placeholder={t('search')}
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FilterBrandProduct page={page}setPage={setPage} itemsPerPage={itemsPerPage} showSelected={false} />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="50%" mt={1} gap={2}>
            <Button
              onClick={handleSelectAll}
              sx={{
                bgcolor: '#101010',
                color: '#fff',
                ':hover': { bgcolor: '#0a0a0a' },
                borderRadius: '20px',
                height: '40px',
                width: '120px',
                textAlign: 'center',
                textTransform: 'capitalize',
              }}
            >
              {allSelected ? t('deselect_all') : t('select_all')}
            </Button>
            <Button
              sx={{
                bgcolor: 'gray',
                ':hover': { bgcolor: 'gray' },
                color: '#fff',
                borderRadius: '20px',
                height: '40px',
                width: '120px',
                textAlign: 'center',
                textTransform: 'capitalize',
                cursor: 'none',
              }}
            >
              {`Selected: ${supplyChainData?.brandProduct?.length}`}
            </Button>
            <Button
              disabled={allSelected || supplyChainData?.brandProduct?.length <= 0}
              onClick={() => setOpenAlertChainDialog(true)}
              sx={{
                bgcolor: supplyChainData?.brandProduct?.length <= 0 || allSelected ? 'gray' : '#000',
                ':hover': { bgcolor: '#101010' },
                color: '#fff',
                borderRadius: '20px',
                height: '40px',
                width: '120px',
                textAlign: 'center',
                textTransform: 'capitalize',
              }}
            >
              {t('Unselect_All')}
            </Button>
          </Box>

          {productLoading ? (
            <Loader />
          ) : (
            <Grid mt={2} container spacing={2}>
              {currentPageData?.map((val, index) => (
                <Grid key={index} item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Tooltip title={val?.supplierChainId && val?.supplierChainId !== id && t('this_product_already_selected_in_supply_chain')} placement="top" arrow>
                    <Grid container bgcolor="#fff" p={1} borderRadius={2}>
                      <Grid item xs={3}>
                        <img src={val?.urls[0]} alt="" height="40px" width="40px" style={{ borderRadius: '10px' }} />
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          sx={{
                            color: '#000',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            display: 'flex',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {val?.title?.value}
                        </Typography>
                        <Typography
                          sx={{
                            color: 'gray',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {val?.qrId}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} textAlign="center">
                        <Checkbox
                          {...label}
                          icon={<CircleOutlinedIcon />}
                          checkedIcon={<CircleIcon />}
                          // checked={supplyChainData?.brandProduct?.some((product) => product.id === val?.id) || (val?.supplierChainId && val?.supplierChainId !== id)}
                          checked={isProductSelected(val)}
                          onChange={(e) => selectProduct(e, val)}
                          disabled={val?.supplierChainId && val?.supplierChainId !== id}
                        />
                      </Grid>
                    </Grid>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box mt={2} display={'flex'} alignItems={'center'} justifyContent={'end'}>
              <Button onClick={handleCreateSupplyChain} sx={{ color: '#fff', bgcolor: '#000', borderRadius: '30px', px: 4, ':hover': { bgcolor: '#000' } }}>
                {updateSupplyChain ? t('save') : t('create')}
              </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
              <Pagination
                sx={{
                  '& .MuiPaginationItem-root': {
                    color: 'gray',
                  },
                  '& .MuiPaginationItem-root.Mui-selected': {
                    backgroundColor: '#EC895A',
                    color: '#fff',
                  },
                }}
                count={pageCount}
                page={page}
                onChange={handleChange}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <AlertDialog
        btnName={t('yes')}
        title={t('remove_all_the_products_connected_to_this_claim')}
        description={t('are_you_sure_you_want_to_remove_all_products')}
        open={openAlertChainDialog}
        handleClick={handleRemoveSelectedProduct}
        handleCloseDialog={() => {
          setOpenAlertChainDialog(false);
        }}
      />
    </>
  );
}
