import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Chip, IconButton, Input, InputAdornment, useMediaQuery, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
//relative path imports
import CommonSelect from '../../utils/CommonSelect';
import AssesmentFooter from '../../utils/AssesmentFooter';
// import { CommonSquareChipsWrapper } from './AssessmentStep4';
import { categoryList } from '../../product_engine/CreateProduct';
import { StyledButton } from '../../utils/LCAStep';
import CommonInput from '../../utils/CommonInput';
import { lca_weight_field_validation_regex } from '../../../data';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const weightUnit = ['g', 'kg', 'oz'];

const AssessmentStep1 = ({ handleSubmit, productInfo, garmentCode  }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const { id } = useParams();
  /*   const [composition, setComposition] = useState('');
  const [compositionPercentage, setCompositionPercentage] = useState(); */
  const [activeTab, setActiveTab] = useState(0);
  const [newComposition, setNewComposition] = useState('');
  const [editingComposition, setEditingComposition] = useState(false);
  const [editingMaterialIndex, setEditingMaterialIndex] = useState(null);
  const [materialData, setMaterialData] = useState({ name: '', percentage: '' });

  const [formData, setFormData] = useState({
    type: '',
    code: '',
    title: '',
    composition: { en: [], fr: [], it: [], de: [], tr: [] },
    weight: { value: '', unit: weightUnit[1] },
  });

  useEffect(() => {
    if (productInfo) {
      // const compositionData = productInfo?.composition?.[currentLanguage || 'en' || 'it'] || productInfo?.composition || [];
      setFormData({
        type: productInfo?.category || productInfo?.type || '',
        title: productInfo?.title?.value || productInfo?.title?.fr || productInfo?.title || productInfo?.title?.it || '',
        /*   composition: Array.isArray(compositionData) && compositionData?.length > 0
        ? compositionData?.flatMap((ele) =>
            Array.isArray(ele?.composition)
              ? ele?.composition?.map((item) => ({
                  ...item,
                  label: `${item?.name || ''}${item?.percentage ? ` -${item.percentage}%` : ''}`,
                }))
              : [{ ...ele, label: `${ele?.name || ''}${ele?.percentage ? ` -${ele?.percentage}%` : ''}` }]
          )
        : [], */
        composition: productInfo?.composition,
        weight: {
          value: String(productInfo?.weight?.value) || '',
          unit: productInfo?.weight?.unit || weightUnit[0],
        },
        code: productInfo?.qrId || productInfo?.code ||  '',
      });
    }
  }, [currentLanguage, productInfo]);

  const onNext = (isDraft = false) => {
    if (formData.type) {
      /* const data = formData.composition?.[currentLanguage]?.map((ele) =>
        ele?.composition?.map((item) => ({ name: item.name, percentage: item.percentage }))
      ); */
      const data = formData.composition;

      handleSubmit({ garment: { ...formData, composition: data } }, isDraft);
    } else {
      setSnackBar({ open: true, message: 'Please select required field.' });
    }
  };

  /*   const deleteProductComposition = (index) => {
    if (index >= 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        composition: prevFormData.composition.filter((_, idx) => idx !== index),
      }));
    }
  }; */

  /*  const addProductCompostition = () => {
    if (composition && compositionPercentage) {
      setFormData((prevFormData) => {
        // Filter out any empty objects from the existing array
        const filteredComposition = prevFormData.composition.filter(
          (item) => item.name && item.percentage
        );
    
        return {
          ...prevFormData,
          composition: [
            ...filteredComposition,
            { 
              label: `${composition}-${compositionPercentage} %`, 
              name: composition, 
              percentage: compositionPercentage 
            },
          ],
        };
      });
      // setFormData((prevFormData) => ({
      //   ...prevFormData,
      //   composition: [...prevFormData.composition, { label: `${composition}-${compositionPercentage} %`, name: composition, percentage: compositionPercentage }],
      // }));
      setComposition('');
      setCompositionPercentage('');
    }
  }; */

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const handleAddMaterial = () => {
    if (materialData?.name.trim() === '' || String(materialData?.percentage).trim() === '') return;

    setFormData((prevFormData) => {
      const updatedCompositions = prevFormData?.composition[currentLanguage]?.map((comp, index) => {
        if (index === activeTab) {
          const existingComposition = Array.isArray(comp?.composition) ? comp?.composition : [];
          if (editingMaterialIndex !== null) {
            const updatedMaterials = existingComposition?.map((mat, i) => (i === editingMaterialIndex ? { ...materialData } : mat));
            return { ...comp, composition: updatedMaterials };
          } else {
            return {
              ...comp,
              composition: [...existingComposition, { ...materialData }],
            };
          }
        }
        return comp;
      });

      return {
        ...prevFormData,
        composition: {
          ...prevFormData.composition,
          [currentLanguage]: updatedCompositions,
        },
      };
    });

    setMaterialData({ name: '', percentage: '' });
    setEditingMaterialIndex(null);
  };

  const handleAddComposition = () => {
    if (newComposition.trim() === '') return;
    setFormData((prevFormData) => {
      const updatedCompositions = editingComposition
        ? prevFormData.composition[currentLanguage]?.map((comp, index) => {
            if (index === activeTab) return { ...comp, componentName: newComposition };
            return comp;
          })
        : [...(prevFormData.composition[currentLanguage] || []), { componentName: newComposition, composition: [] }];

      const newActiveTab = editingComposition ? activeTab : updatedCompositions.length - 1;

      setActiveTab(newActiveTab);

      return {
        ...prevFormData,
        composition: {
          ...prevFormData.composition,
          [currentLanguage]: updatedCompositions,
        },
      };
    });
    setNewComposition('');
    setEditingComposition(false);
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
    setEditingComposition(false);
    setEditingMaterialIndex(null);
  };

  const handleDelete = (index) => {
    setFormData((prevFormData) => {
      const updatedCompositions = prevFormData.composition[currentLanguage].filter((_, i) => i !== index);
      setActiveTab((prevActiveTab) => {
        if (updatedCompositions.length === 0) {
          return -1;
        } else if (prevActiveTab >= updatedCompositions.length) {
          return updatedCompositions.length - 1;
        } else {
          return prevActiveTab;
        }
      });

      return {
        ...prevFormData,
        composition: {
          ...prevFormData.composition,
          [currentLanguage]: updatedCompositions,
        },
      };
    });
  };

  const handleEditComposition = () => {
    setNewComposition(formData.composition[currentLanguage][activeTab]?.componentName || '');
    setEditingComposition(true);
  };

  const handleEditMaterial = (materialIndex) => {
    const materialToEdit = formData.composition[currentLanguage][activeTab]?.composition[materialIndex];
    setMaterialData(materialToEdit);
    setEditingMaterialIndex(materialIndex);
  };

  const handleDeleteMaterial = (materialIndex) => {
    const updatedCompositions = formData.composition[currentLanguage].map((comp, index) => {
      if (index === activeTab) {
        return {
          ...comp,
          composition: comp.composition.filter((_, i) => i !== materialIndex),
        };
      }
      return comp;
    });
    setFormData((prevState) => ({
      ...prevState,
      composition: {
        ...prevState.composition,
        [currentLanguage]: updatedCompositions,
      },
    }));
  };

  const isDisabled =
    !formData.type /* || !formData.code  */ || !formData.title || !(formData.composition?.[currentLanguage] && formData.composition[currentLanguage].length > 0) || !formData.weight.value;

  return (
    <Box>
      <Box mt={5}>
        <Card sx={{ borderRadius: '20px', px: isMobile ? 1 : 4 }}>
          <Box my={3}>
            <Typography textTransform="uppercase" fontWeight="700" variant="h5" color="#0E1090">
              {t('garment')}
            </Typography>

            <CommonSelect
              label={
                <span style={{ cursor: 'pointer' }}>
                  {t('type_of_garment')} <span style={{ color: 'red' }}>*</span>
                  <Tooltip title={'Please indicate the type of garment that you want to analyze'} placement="top-start">
                    <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                  </Tooltip>
                </span>
              }
              options={categoryList}
              value={formData.type}
              handleChange={(e) => setFormData({ ...formData, type: e.target.value })}
            />
            {/* {CommonInfoWrapper(`Please indicate the type of garment that you want to analyze`)} */}

            <CommonInput
              label={
                <span>
                  {t('garment_code')}
                  <Tooltip title={'Please indicate a SKU to indentify the garment you want to analyze. This name will be reproted in the results'} placement="top-start">
                    <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                  </Tooltip>
                </span>
              }
              value={formData?.code}
              handleChange={(e) => {
                const value = e.target.value;
                if (value.length <= 25) setFormData({ ...formData, code: value });
              }}
              type="text"
              isSpacing
              variant="standard"
              autoComplete={false}
              disabled={garmentCode === undefined ? false : true}
            />
            {/* {CommonInfoWrapper(`Please indicate a SKU to indentify the garment you want to analyze. This name will be reproted in the results`)} */}

            <CommonInput
              label={
                <span>
                  {t('garment_title')}
                  <span style={{ color: 'red' }}>*</span>
                </span>
              }
              value={formData.title}
              handleChange={(e) => {
                const value = e.target.value;
                if (value.length <= 25) {
                  setFormData({ ...formData, title: value });
                }
              }}
              type="text"
              isSpacing
              variant="standard"
              autoComplete={false}
            />
            <Typography sx={{ marginTop: '20px', color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer' }}>
              {t('product_composition')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Input
              id="input-with-icon-textfield"
              placeholder={t('enter_product_composition_name')}
              sx={{ width: '100%', paddingBottom: '2px' }}
              variant="standard"
              value={newComposition}
              onChange={(e) => setNewComposition(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <Button sx={{ background: '#0e1090', borderRadius: '20px', ':hover': { background: '#0e1090' } }} size="small" variant="contained" onClick={handleAddComposition}>
                    {editingComposition ? 'Update Composition' : 'Add Composition'}
                  </Button>
                </InputAdornment>
              }
            />
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                margin: '10px 0',
              }}
            >
              {Array.isArray(formData?.composition?.[currentLanguage]) &&
                formData.composition[currentLanguage].length > 0 &&
                formData.composition[currentLanguage].map((composition, index) => {
                  const wordCount = (composition.componentName || '').split(' ').length;
                  const isLongText = wordCount > 20;
                  return (
                    <div
                      key={index}
                      onClick={() => handleTabClick(index)}
                      style={{
                        padding: '2px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Tooltip title={composition.componentName || `Component ${index + 1}`} arrow placement="top">
                        <Chip
                          key={index}
                          label={
                            <span
                              style={{
                                wordBreak: 'break-word',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '200px',
                                display: 'block',
                                overflowWrap: 'break-word',
                              }}
                            >
                              {composition.componentName || `Component ${index + 1}`}
                            </span>
                          }
                          onDelete={(e) => {
                            e.stopPropagation();
                            handleDelete(index);
                          }}
                          sx={{
                            mb: '2px',
                            border: activeTab === index ? '2px solid #6c9ae0' : 'transparent',
                            padding: isLongText ? '12px' : '8px',
                            maxHeight: isLongText ? '100px' : 'auto',
                            overflowY: isLongText ? 'auto' : 'visible',
                          }}
                        />
                      </Tooltip>
                    </div>
                  );
                })}
            </div>
            {/* {CommonSquareChipsWrapper(true, formData?.composition, deleteProductComposition)} */}

            {formData?.composition?.[currentLanguage]?.[activeTab]?.componentName && (
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box mt={1} minWidth="44%">
                  <CommonInput
                    label={<span>{t('composition')}</span>}
                    placeholder={t('composition')}
                    value={materialData.name}
                    handleChange={(e) => {
                      const value = e.target.value.trimStart();
                      setMaterialData((prev) => ({ ...prev, name: value }));
                    }}
                    type="text"
                    isSpacing
                    variant="standard"
                    autoComplete={false}
                  />
                </Box>

                <Box mt={1} minWidth="44%">
                  <CommonInput
                    label={<span>{t('percentage')}</span>}
                    placeholder={t('percentage')}
                    value={materialData.percentage}
                    handleChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[+-]/g, '');
                      const regex = /^((100|[0-9]{1,2})(\.[0-9]{1,2})?)?$/;
                      if (regex.test(value)) {
                        const floatValue = parseFloat(value);
                        if (value === '' || (!isNaN(floatValue) && floatValue <= 100)) {
                          setMaterialData((prev) => ({ ...prev, percentage: value }));
                        }
                      }
                      /*    if (value === '0.00' || value === '0.0000') {
                      setCompositionPercentage('');
                    } else if (regex.test(value) || value === '') {
                      const floatValue = parseFloat(value);
                      if (value === '' || (!isNaN(floatValue) && floatValue <= 100)) {
                        setCompositionPercentage(floatValue);
                      }
                    } */
                    }}
                    type="number"
                    isSpacing
                    variant="standard"
                    autoComplete={false}
                  />
                </Box>
                <Box mt={1} display="flex" alignItems="center" justifyContent="end" onClick={() => handleAddMaterial()}>
                  <StyledButton variant="contained" isBgColor="#0E1090">
                    {editingMaterialIndex !== null ? t('update') : t('ADD')}
                  </StyledButton>
                </Box>
              </Box>
            )}

            {formData?.composition?.[currentLanguage]?.[activeTab]?.componentName &&
              formData?.composition?.[currentLanguage]?.[activeTab]?.composition?.length > 0 &&
              Array.isArray(formData?.composition?.[currentLanguage]) &&
              formData?.composition[currentLanguage][activeTab] && (
                <Box
                  sx={{
                    border: '1px solid gray',
                    width: '300px',
                    padding: '8px',
                    borderRadius: '8px',
                    marginTop: '16px',
                  }}
                >
                  <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 600 }}>
                    {formData?.composition[currentLanguage][activeTab]?.componentName}
                    <IconButton onClick={handleEditComposition} sx={{ marginLeft: '8px' }}>
                      <EditIcon />
                    </IconButton>
                  </Typography>

                  <Typography sx={{ fontWeight: 600, fontSize: '14px', marginTop: '12px' }}>Materials:</Typography>
                  <Box
                    sx={{
                      maxHeight: '150px',
                      overflowY: 'auto',
                      borderRadius: '5px',
                      padding: '5px',
                      marginTop: '5px',
                      scrollbarWidth: 'none',
                      '&::-webkit-scrollbar': {
                        display: 'none',
                      },
                    }}
                  >
                    {formData?.composition[currentLanguage][activeTab]?.composition?.length > 0 ? (
                      formData?.composition[currentLanguage][activeTab].composition.map((material, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: '1px solid #ddd',
                            padding: '0 6px',
                            marginBottom: '6px',
                            borderRadius: '6px',
                            backgroundColor: '#fff',
                            '&:hover': {
                              backgroundColor: '#f0f0f0',
                            },
                            width: '100%',
                          }}
                        >
                          <Typography variant="body2" sx={{ fontSize: '14px', wordBreak: 'break-word', whiteSpace: 'normal', width: '60%' }}>
                            {material?.name}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: '600', textAlign: 'center', marginLeft: '10px', width: '15%' }}>
                            {' '}
                            {material?.percentage}%
                          </Typography>
                          <Box sx={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                            <IconButton sx={{ cursor: 'pointer', color: '#1976d2' }} onClick={() => handleEditMaterial(index)}>
                              <EditIcon style={{ fontSize: '16px' }} />
                            </IconButton>
                            <IconButton sx={{ color: 'red', cursor: 'pointer' }} onClick={() => handleDeleteMaterial(index)}>
                              <DeleteIcon style={{ fontSize: '16px' }} />
                            </IconButton>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Typography variant="body2" sx={{ color: '#888', fontStyle: 'italic', mt: 2 }}>
                        No materials added for this component.
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}

            <Grid container>
              <Grid item xs={10}>
                <CommonInput
                  label={
                    <span>
                      {t('weight')}
                      <span style={{ color: 'red' }}>*</span>
                      <Tooltip title={'What is the weight of the garment?'} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  placeholder={t('enter_detail_here')}
                  value={formData.weight.value}
                  type="text"
                  variant="standard"
                  autoComplete={false}
                  // handleChange={(e) => {
                  //   let value = e.target.value;
                  //   value = value.replace(/[+-]/g, '');
                  //   const regex = /^(?!0\.00$)([1-9][0-9]{0,4}|0)(\.[0-9]{0,4})?$/;
                  //   if (regex.test(value) || value === '') {
                  //     const floatValue = parseFloat(value);
                  //     if (value === '' || (!isNaN(floatValue) && floatValue <= 999.99)) {
                  //       setFormData({ ...formData, weight: { ...formData.weight, value: e.target.value } });
                  //     }
                  //   }
                  // }}
                  handleChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || lca_weight_field_validation_regex.test(value)) {
                      // if (value !== '0') {
                      setFormData({ ...formData, weight: { ...formData.weight, value: String(e.target.value) } });
                      // }
                    }
                  }}
                />
                {/* {CommonInfoWrapper(`What is the weight of the garment?`)} */}
              </Grid>
              <Grid item xs={2}>
                <FormControl size="small" sx={{ marginTop: '36px' }} fullWidth variant="filled">
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    fullWidth
                    sx={{ border: 'none' }}
                    size="small"
                    value={formData.weight.unit}
                    onChange={(e) => setFormData({ ...formData, weight: { ...formData.weight, unit: e.target.value } })}
                    autoWidth
                  >
                    {weightUnit.map((unit) => (
                      <MenuItem key={unit} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>

      <AssesmentFooter isDraftDisabled={isDisabled} isNextBtnDisabled={isDisabled} onNext={onNext} Typography1="Answer the category" Typography2="Continue to the next question" />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Box>
  );
};

export default AssessmentStep1;
