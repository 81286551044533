import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';


const initialState = {
  certificate: [],
  loading: false,
  error: null,
};

export const fetchCertificate = createAsyncThunk(
  'fetch/certificate', 
  async ({page,itemPerPage,userId}, { rejectWithValue }) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.CERTIFICATE}/?page=${page}&limit=${itemPerPage}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          userid:userId
        },
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message); 
    }
  }
);

export const searchCertificate = createAsyncThunk(
  'search/certificate', 
  async ({term,userId}, { rejectWithValue }) => {
    try {
      const encodedTerm = encodeURIComponent(term); 
      const response = await httpService.get(`${RestUrlsConstants.CERTIFICATE}/?query=${encodedTerm}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          userid:userId
        },
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message); 
    }
  }
);
export const sortCertificate = createAsyncThunk(
  'sort/certificate', 
  async ({page,itemPerPage,userId,sort,orderBy}, { rejectWithValue }) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.CERTIFICATE}/?orderBy=${orderBy}&sortBy=${sort}&page=${page}&limit=${itemPerPage}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          userid:userId
        },
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message); 
    }
  }
);

const certificateProofPoints = createSlice({
  name: 'certificatesGoals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCertificate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.certificate = action.payload;
      })
      .addCase(fetchCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(searchCertificate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchCertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.certificate = action.payload;
      })
      .addCase(searchCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(sortCertificate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sortCertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.certificate = action.payload;
      })
      .addCase(sortCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default certificateProofPoints.reducer;
