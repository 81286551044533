import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
//import icons
import { IoMdClose } from 'react-icons/io';
import { LuDownload, LuUpload } from 'react-icons/lu';
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';
//relative path imports
import useCheckOnlineStatus from '../../../../hooks/useOnlineCheckStatus';
import { RestUrlsConstants } from '../../../../constants/rest-urls.constants';
import httpService from '../../../../service/http.service';
import { CommonLink } from '../../../../constants/common.constants';
import PATH from '../../../utils/path';

//create mui styled components
const StyledButton = styled(Button)(({ theme, isBgColor }) => ({
  backgroundColor: isBgColor || '#101010',
  padding: '12px 16px',
  borderRadius: theme.spacing(4),
  fontWeight: '700',
  color: '#fff',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: isBgColor || '#101010',
    boxShadow: 'none',
  },
}));

const CenteredText = styled(Typography)(({ theme, color }) => ({
  textAlign: 'center',
  color: color || '#101010',
  fontWeight: '700',
  marginTop: theme.spacing(2),
}));

const UploadCSVModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const csvTemplateDownloadBtn = async () => {
    if (!checkOnlineStatus()) return;
    try {
      const response = await httpService.get(`${RestUrlsConstants.lca_csv_template_download}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
      });
      const contentType = response.headers['content-type'];
      const contentDisposition = response.headers['content-disposition'];

      let fileName = '';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }
      const blob = new Blob([response.data], { type: contentType });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);
      setSnackBar({ open: true, message: 'File template downloaded successfully.' });
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: 'Error while downloading CSV template.' });
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!checkOnlineStatus()) return;
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await httpService.post(`${RestUrlsConstants.upload_csv}`, formData, {
          headers: {
            Authorization: localStorage.getItem('Authorization'),
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response?.data) {
          setSnackBar({ open: true, message: response?.data?.message });
          setTimeout(() => {
            onClose();
          }, 500);
          navigate(`${PATH.VIEWRESULT}?state=2`);
        }
      } catch (error) {
        console.log(error);
        setSnackBar({ open: true, message: error?.response?.data?.message });
      } finally {
        setLoading(false);
      }
    }
  };

  const pdfDownload = async () => {
    if (!checkOnlineStatus()) return;
    try {
      /* const response = await httpService.get(`${RestUrlsConstants.pdf_download}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
      });
      const contentType = response.headers['content-type'];
      const contentDisposition = response.headers['content-disposition'];

      let fileName = '';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }
      const blob = new Blob([response.data], { type: contentType });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl); */
      window.open(CommonLink?.templateAccessGuide, '_blank');
      // setSnackBar({ open: true, message: 'pdf downloaded successfully.' });
      setTimeout(() => {
        onClose();
      }, 500);
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.status === 500 && 'Oops! Something went wrong. Please try again.';
      setSnackBar({ open: true, message: errorMessage });
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: { p: 2, borderRadius: 2 },
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle sx={{ fontWeight: 700, fontSize: 24, color: '#000' }}>{t('upload_file')}</DialogTitle>
        <IconButton onClick={onClose} sx={{ color: '#000' }}>
          <IoMdClose />
        </IconButton>
      </Stack>

      <DialogContent>
        <CenteredText>{t('download_lca_input_template')}</CenteredText>
        <Typography color="#808080" textAlign="center" mt={2}>
          {t('download_lca_input_template_desc')}
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <StyledButton onClick={csvTemplateDownloadBtn} endIcon={<LuDownload />}>
            {t('download_file')}
          </StyledButton>
        </Box>

        <Box mt={3}>
          <CenteredText>{t('how_to_use_the_template')}</CenteredText>
          <Typography color="#808080" textAlign="center" mt={2}>
            {t('how_to_use_the_template_desc')}
          </Typography>
          <Box display="flex" justifyContent="center" mt={2}>
            <StyledButton onClick={pdfDownload} endIcon={<BsFillFileEarmarkPdfFill />}>
              {t('access_guide')}
            </StyledButton>
          </Box>
        </Box>
        <Box mt={3}>
          <CenteredText fontSize={16}>{t('upload_lca_input_template')}</CenteredText>
          <Typography color="#808080" textAlign="center" mt={2}>
            {t('upload_lca_input_template_desc')}
          </Typography>
          {loading ? (
            <Typography mt={2} fontSize={20} textAlign={'center'} fontWeight={'700'}>
              Uploading
            </Typography>
          ) : (
            <Box display="flex" alignItems={'center'} justifyContent="center" mt={2}>
              <input type="file" accept=".xlsx" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
              <StyledButton onClick={handleUploadClick} isBgColor="#0E1090" endIcon={<LuUpload />}>
                {t('upload_file')}
              </StyledButton>
            </Box>
          )}
        </Box>
      </DialogContent>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Dialog>
  );
};

export default UploadCSVModal;
