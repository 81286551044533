import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField } from '@mui/material';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';
import { useNavigate } from 'react-router-dom';
import PATH from './utils/path';

const BrandSearch = ({ user }) => {
  const [inputValue, setInputValue] = useState(user.brand.name);
  const [value, setValue] = useState({ id: user.brand.id, name: user.brand.name });
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (open && options.length === 0) {
      fetchBrandOption();
    }
  }, [open, options]);

  const fetchBrandOption = async () => {
    setLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.brandUrl}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      setOptions(response.data.data);
    } catch (error) {
      console.error('Error fetching brands:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (Id) => {
    const updatedUser = {
      brandId: Id,
    };
    try {
      const response = await httpService.put(`${RestUrlsConstants.userUrl}${user.id}`, updatedUser, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response?.data?.status) {
        navigate(`${PATH.HOME}`);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = async (e, newValue) => {
    localStorage.removeItem('filters');
    localStorage.removeItem('searchValues')
    setValue(newValue);
    await updateUser(newValue?.id);
  };

  return (
    <div>
      <Autocomplete
        value={value}
        disableClearable
        size="small"
        sx={{ width: 200 }}
        inputValue={inputValue}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        onChange={(e, newValue) => handleChange(e, newValue)}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select brand"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: 'none',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#101010',
                },
              },
            }}
          />
        )}
        noOptionsText={loading ? <CircularProgress color="inherit" size={20} /> : 'No options'}
      />
    </div>
  );
};

export default BrandSearch;
