import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { StyledButton } from '../../utils/LCAStep';
import { Box, Input, InputAdornment, Stack, useTheme } from '@mui/material';
import CommonSelect from '../../utils/CommonSelect';
import AssesmentFooter from '../../utils/AssesmentFooter';
import { five_digit_validation_regex, lca_weight_field_validation_regex, location, transportationType, weightUnit, yarnMenufacturingMethod } from '../../../data';
import CommonInput from '../../utils/CommonInput';

const AssessmentStep8 = ({ handleSubmit, data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [chipsData, setChipsData] = useState([]);
  const [selectedChipIndex, setSelectedChipIndex] = useState(null);
  const [newChipName, setNewChipName] = useState('');

  useEffect(() => {
    if (data && data?.yarn?.length > 0) {
      const lastChipIndex = data.yarn?.length - 1;
      const updatedChips = data?.yarn?.map((item, index) => ({
        [item.name]: {
          method: item.method || '',
          quantity: item.quantity || '',
          yarnCount: item.yarnCount || '',
          transportType: item.transportType || '',
          transportDistance: item.transportDistance || '',
          transportLocation: item.transportLocation || '',
          selected: index === lastChipIndex,
        },
      }));
      setChipsData(updatedChips);
      setSelectedChipIndex(lastChipIndex);
    }
  }, [data]);

  const onNext = (isDraft = false) => {
    if (chipsData.length > 0) {
      if (getCompletedChips().length > 0) {
        handleSubmit({ yarn: getCompletedChips() }, isDraft);
        setSelectedChipIndex(null);
      } else {
        setSnackBar({ open: true, message: 'Please select atleast one yarn name' });
      }
    } else {
      handleSubmit({ yarn: getCompletedChips() }, isDraft);
      setSelectedChipIndex(null);
    }
  };

  const handleClick = (index) => {
    const selectedChip = chipsData[index];
    if (selectedChip) {
      const updatedChips = chipsData.map((chip, i) => {
        const chipLabel = Object.keys(chip)[0];
        return {
          [chipLabel]: { ...chip[chipLabel], selected: i === index },
        };
      });

      setChipsData(updatedChips);
      setSelectedChipIndex(index);
    }
  };
  
  const onAddChip = () => {
    if (!newChipName.trim()) {
      setSnackBar({ open: true, message: 'Yarn name cannot be empty.' });
      return;
    }
    if (chipsData.some((chip) => chip[newChipName.trim()])) {
      setSnackBar({ open: true, message: 'Same yarn name already exists.' });
      return;
    }
    if (chipsData.length >= 4) {
      setSnackBar({ open: true, message: 'You can only add up to four yarn names.' });
      return;
    }
    const newChip = {
      [newChipName.trim()]: {
        method: '',
        quantity: '',
        yarnCount: '',
        transportType: '',
        transportDistance: '',
        transportLocation: '',
        selected: true,
      },
    };
    setChipsData((prevChips) => [
      ...prevChips.map((chip) => {
        const chipLabel = Object.keys(chip)[0];
        return { [chipLabel]: { ...chip[chipLabel], selected: false } };
      }),
      newChip,
    ]);

    setNewChipName('');
    setSelectedChipIndex(chipsData.length);
  };

  const handleDelete = (index) => {
    const updatedChips = chipsData.filter((_, i) => i !== index);
    let newSelectedChipIndex = null;

    if (updatedChips.length > 0) {
      if (index === chipsData.length - 1) {
        newSelectedChipIndex = updatedChips.length - 1;
      } else {
        newSelectedChipIndex = index;
      }
      const chipLabel = Object.keys(updatedChips[newSelectedChipIndex])[0];
      updatedChips[newSelectedChipIndex] = {
        [chipLabel]: { ...updatedChips[newSelectedChipIndex][chipLabel], selected: true },
      };
    }

    setChipsData(updatedChips);
    setSelectedChipIndex(newSelectedChipIndex);
  };

  const isChipCompleted = (chipIndex, label) => {
    let status;
    chipsData.forEach((item, index) => {
      if (index === chipIndex) {
        const { method, quantity, transportType, transportDistance, transportLocation } = item[label];
        if (method && quantity && transportType && transportDistance && transportLocation) {
          status = true;
        } else status = false;
      }
    });
    return status;
  };

  const getFieldValues = () => {
    if (selectedChipIndex !== null && chipsData[selectedChipIndex]) {
      const selectedChipObj = chipsData[selectedChipIndex];
      const chipLabel = Object.keys(selectedChipObj)[0];
      return selectedChipObj[chipLabel];
    }
    return {};
  };

  const getCompletedChips = () => {
    const result = chipsData
      .filter((item, index) => {
        const chipLabel = Object.keys(item)[0];
        return isChipCompleted(index, chipLabel);
      })
      .map((item) => {
        const chipLabel = Object.keys(item)[0];
        const { selected, ...chipData } = item[chipLabel];
        return {
          name: chipLabel,
          ...chipData,
        };
      });
    return result;
  };

  const updateFieldValues = (fieldName, newValue) => {
    if (selectedChipIndex != null) {
      const selectedChipObj = chipsData[selectedChipIndex];
      const chipLabel = Object.keys(selectedChipObj)[0];
      setChipsData((prevData) => {
        const updatedData = [...prevData];

        if (updatedData[selectedChipIndex] && updatedData[selectedChipIndex][chipLabel]) {
          updatedData[selectedChipIndex] = {
            ...updatedData[selectedChipIndex],
            [chipLabel]: {
              ...updatedData[selectedChipIndex][chipLabel],
              [fieldName]: newValue,
            },
          };
        } else {
          console.log(`Invalid selectedChipIndex, chipLabel, or field name: selectedChipIndex ${selectedChipIndex}, chipLabel ${chipLabel}, Field ${fieldName}`);
        }

        return updatedData;
      });
    } else {
      setSnackBar({ open: true, message: 'Please add atleast one yarn name' });
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const isDisabled = useMemo(() => {
    const completedChips = getCompletedChips();
    const areAllChipsComplete = completedChips.length === chipsData.length;
    const currentFieldValues = getFieldValues();
    const areCurrentFieldsValid =
      currentFieldValues?.method && currentFieldValues?.quantity && currentFieldValues?.transportType && currentFieldValues?.transportDistance && currentFieldValues?.transportLocation;
    return !areAllChipsComplete || !areCurrentFieldsValid;
  }, [getCompletedChips, chipsData.length, getFieldValues]);

  return (
    <Box>
      <Box mt={5}>
        <Card sx={{ borderRadius: '20px', px: isMobile ? 1 : 4 }}>
          <Box my={3}>
            <Typography textTransform="uppercase" fontWeight="700" variant="h5" color="#0E1090">
              {t('yarn_manufacturing')}
            </Typography>

            <Box my={3}>
              <Typography sx={{ cursor: 'pointer' }} fontWeight={'700'} pb={4}>
                {t('spinning')}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Input
                id="input-with-icon-textfield"
                placeholder="Enter Yarn Name"
                sx={{ width: '100%', paddingBottom: 2 }}
                variant="standard"
                value={newChipName}
                onChange={(e) => {
                  const value = e.target.value;
                  const isValid = /^[a-zA-Z\s]*$/.test(value);
                  if (isValid && value.length <= 250) {
                    setNewChipName(value);
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <StyledButton variant="contained" isBgColor="#0E1090" onClick={onAddChip}>
                      {t('ADD')}
                    </StyledButton>
                  </InputAdornment>
                }
              />
            </Box>

            {chipsData.length > 0 && (
              <Box mt={2}>
                {chipsData.map((chip, index) => {
                  const label = Object.keys(chip)[0];
                  return (
                    <Chip
                      key={index}
                      label={label}
                      onDelete={() => handleDelete(index)}
                      onClick={() => handleClick(index)}
                      color={chip[label].selected ? 'primary' : 'default'}
                      sx={{ marginRight: 1, marginBottom: 1, cursor: 'pointer' }}
                    />
                  );
                })}
              </Box>
            )}

            <Box>
              <Stack mt={2}>
                <CommonSelect
                  label={
                    <span style={{ cursor: 'pointer' }}>
                      {t('Method')} <span style={{ color: 'red' }}>*</span>
                      <Tooltip
                        title={'Please select the manufacturing processes that are applied to the produce the textiles that you use in your product (more than one answer allowed)'}
                        placement="top-start"
                      >
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  options={yarnMenufacturingMethod}
                  value={getFieldValues()?.method || ''}
                  handleChange={(e) => updateFieldValues('method', e.target.value)}
                />

                <Box>
                  <Box mt={3}>
                    <Typography sx={{ cursor: 'pointer' }} fontWeight={'700'}>
                      {t('quantity')} <span style={{ color: 'red' }}>*</span>
                      <Tooltip title={'Please indicate the quantity of textile that is used in your product (in kg), per each manufacturing method'} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </Typography>
                    <Grid container>
                      <Grid item xs={10}>
                        <CommonInput
                          type="text"
                          isSpacing
                          variant="standard"
                          autoComplete={false}
                          value={getFieldValues()?.quantity || ''}
                          handleChange={(e) => {
                            const value = e.target.value;
                            if (value === '' || (lca_weight_field_validation_regex.test(value) && value !== '0')) {
                              updateFieldValues('quantity', value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl size="small" fullWidth variant="filled">
                          <Select fullWidth sx={{ border: 'none' }} size="small" value="kg" disabled>
                            {weightUnit.map((unit) => (
                              <MenuItem key={unit} value={unit}>
                                {unit}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <CommonInput
                  label={
                    <span>
                      {t('yarn_count')}{' '}
                      <Tooltip title={'Please indicate the yarn count (in dtex) of the knitted and woven textile used in your product. NOT FOR NON-WOVEN FABRICS'} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  type="text"
                  variant="standard"
                  autoComplete={false}
                  value={getFieldValues()?.yarnCount || ''}
                  handleChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || (lca_weight_field_validation_regex.test(value) && value !== '0')) {
                      updateFieldValues('yarnCount', value);
                    }
                  }}
                />

                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box minWidth="33%">
                    <CommonSelect
                      label={
                        <span style={{ cursor: 'pointer' }}>
                          {' '}
                          {t('transportation_type')} <span style={{ color: 'red' }}>*</span>
                          <Tooltip
                            title={'Please indicate the location of supplier (if known), the distance between the supplier and your next production site and the main mean of transport used.'}
                            placement="top-start"
                          >
                            <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                          </Tooltip>
                        </span>
                      }
                      options={transportationType}
                      value={getFieldValues()?.transportType || ''}
                      handleChange={(e) => updateFieldValues('transportType', e.target.value)}
                    />
                  </Box>

                  <Box minWidth="33%">
                    <CommonInput
                      label={
                        <span>
                          {t('distance')} <span style={{ color: 'red' }}>*</span>
                          <Tooltip
                            title={'Please indicate the location of supplier (if known), the distance between the supplier and your next production site and the main mean of transport used.'}
                            placement="top-start"
                          >
                            <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                          </Tooltip>
                        </span>
                      }
                      type="text"
                      variant="standard"
                      autoComplete={false}
                      fullWidth
                      value={getFieldValues()?.transportDistance || ''}
                      handleChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || (value !== '0' && lca_weight_field_validation_regex.test(value))) {
                          updateFieldValues('transportDistance', value);
                        }
                      }}
                    />
                  </Box>

                  <Box minWidth="33%">
                    <CommonSelect
                      label={
                        <span style={{ cursor: 'pointer' }}>
                          {t('location')} <span style={{ color: 'red' }}>*</span>
                          <Tooltip
                            title={'Please indicate the location of supplier (if known), the distance between the supplier and your next production site and the main mean of transport used.'}
                            placement="top-start"
                          >
                            <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                          </Tooltip>
                        </span>
                      }
                      options={location}
                      value={getFieldValues()?.transportLocation || ''}
                      handleChange={(e) => updateFieldValues('transportLocation', e.target.value)}
                    />
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Card>
      </Box>

      <AssesmentFooter isDraftDisabled={isDisabled} isNextBtnDisabled={isDisabled} onNext={onNext} Typography1="Answer the category" Typography2="Continue to the next question" />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Box>
  );
};

export default AssessmentStep8;
