import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
//import icons
import CloseIcon from '@mui/icons-material/Close';
import DEFAULT_IMAGE from '../../../../images/default_product_image.png';
//import images
import BG_CO2 from '../../../../images/bg-co2.png';
import CO2_CUP from '../../../../images/co2-cup.png';
import vegan from '../../../../images/vegan.svg';
//relative path imports
import { StyledList, StyledText } from '../../AssessmentDashboard';
import { indicatorsType, lcaIndicatorsSvg, lcaIndicatorsType, ObjIndicatorsType, selectLcaIndicatorsType } from '../../../../data';
import httpService from '../../../../service/http.service';
import { RestUrlsConstants } from '../../../../constants/rest-urls.constants';
import useCheckOnlineStatus from '../../../../hooks/useOnlineCheckStatus';
import { CircularProgress, Snackbar, Tooltip } from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import { FaCheckCircle } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';
import CommonInput from '../../../utils/CommonInput';
import { StyledButton } from '../../start_assessment/AssessmentStep3';
import CommonSelect from '../../../utils/CommonSelect';
import { useSelector } from 'react-redux';
import AlertDialog from '../../../utils/AlertDialog';

//create styled components
const StyledProductCard = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  padding: '16px 8px',
  backgroundColor: '#EFEFEF',
  width: '100%',
  flexDirection: 'column',
  minHeight: '100%',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1.5),
  width: '100%',
  backgroundColor: '#EFEFEF',
  minHeight: '100%',
}));

const chip_indicator = {
  Impact: '#FF5722',
  Inventory: '#808080',
};

const StyledProductDescText = styled(Typography)(({}) => ({
  color: '#545454',
  fontSize: '12px',
  display: 'inline',
  lineHeight: '14px',
}));

const ProductResultsModal = ({ open, onClose, completeLcaId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [productResult, setProductResult] = useState([]);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [editField, setEditField] = useState(null);
  const [editedValue, setEditedValue] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [toggleBtn, setToggleBtn] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletedId, setDeletedId] = useState('');
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const user = useSelector((store) => store.user);

  useEffect(() => {
    if (completeLcaId) {
      fetchSingleLCA(completeLcaId);
    }
  }, [completeLcaId]);

  const fetchSingleLCA = async (lcaID) => {
    if (!checkOnlineStatus()) return;
    setLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.LCA}/${lcaID}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data) {
        const productID = response?.data?.data?.productId;
        setData(response?.data?.data);
        if (productID) {
          fetchSingleProduct(productID);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSingleProduct = async (productID) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.product}/${productID}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data) {
        setProductInfo(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const formatQuantity = (quantity) => {
    const [integerPart, decimalPart] = String(quantity)?.toString()?.split('.');
    const formattedInteger = integerPart.slice(0, 5);
    const formattedDecimal = decimalPart ? decimalPart.slice(0, 5) : '';
    return formattedDecimal ? `${formattedInteger}.${formattedDecimal}` : formattedInteger;
  };

  const handleEdit = (field, currentValue, unitValue, typeValue, indicatorValue, index) => {

    const editResult = [...data?.result];

    editResult[index] = {
      ...editResult[index],
      type: typeValue,
      quantity: currentValue,
      unit: unitValue,
      indicator: indicatorValue,
    };
    setUpdateData(editResult);

    setEditField(field);
    setEditedValue({ quantity: currentValue, unit: unitValue, type: typeValue, indicator: indicatorValue });
    setEditIndex(index);
    setToggleBtn(true);
  };

  const handleCircle = async (index) => {
    if (editField && editIndex !== null) {
      const updatedResult = [...updateData];

      updatedResult[editIndex] = {
        ...updatedResult[editIndex],
        type: editedValue?.type,
        quantity: editedValue?.quantity,
        unit: editedValue?.unit,
        indicator: editedValue?.indicator,
      };

      setData((prevData) => ({ ...prevData, result: updatedResult }));
      setToggleBtn(false);
      setEditField(null);
      setEditedValue('');
      setEditIndex(null);
    }
  };

  const handleDelete = async (index) => {
    if (index === null || index < 0 || !data?.result?.length) return;

    const updatedResult = [...data?.result];
    updatedResult.splice(index, 1);

    setData((prevData) => ({ ...prevData, result: updatedResult }));
  };

  const handleSaveEdit = async () => {
    const updatedResult = [...data?.result];
    try {
      const response = await httpService.patch(
        `${RestUrlsConstants.LCA}/${data?.id}`,
        { result: updatedResult },
        {
          headers: {
            Authorization: localStorage.getItem('Authorization'),
          },
        }
      );
      if (response?.data) {
        setSnackBar({ open: true, message: 'Update successful' });
        setData((prevData) => ({ ...prevData, result: updatedResult }));
        setEditField(null);
        setEditedValue('');
        setEditIndex(null);
      }
    } catch (error) {
      setSnackBar({ open: true, message: 'Update failed' });
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setDeletedId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Paper sx={{ p: 4 }}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography fontWeight={'700'} fontSize={24}>
            Product Results
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box mt={2}>
          <Grid container spacing={6}>
            <Grid item md={4}>
              <StyledProductCard>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <img src={productInfo?.urls?.[0] || DEFAULT_IMAGE} alt="product_img" width={140} height={140} style={{ objectFit: 'cover' }} />
                </Box>
                <Stack width={'100%'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                  {data?.name && (
                    <Typography mt={1} fontSize={14} color={'#0E1090'} fontWeight={'700'}>
                      {data?.name}
                    </Typography>
                  )}
                  {productInfo?.title ? (
                    <Box sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <Typography fontSize={12} mt={1} fontWeight={'700'}>
                        {typeof productInfo?.title === 'object' ? productInfo?.title?.value : productInfo?.title}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <Typography fontSize={12} mt={1} fontWeight={'700'}>
                        N/A
                      </Typography>
                    </Box>
                  )}
                  <Stack mt={1}>
                    {productInfo?.weight?.value || productInfo?.weight?.unit ? (
                      <StyledProductDescText>
                        {t('weight')}&nbsp;:&nbsp;
                        <Typography fontSize={12} component="span" fontWeight={'700'}>
                          {productInfo?.weight?.value || 'N/A'}&nbsp;{productInfo?.weight?.unit || ''}
                        </Typography>
                      </StyledProductDescText>
                    ) : (
                      <StyledProductDescText>
                        {t('weight')}&nbsp;:&nbsp;
                        <Typography fontSize={12} component="span" fontWeight={'700'}>
                          N/A
                        </Typography>
                      </StyledProductDescText>
                    )}
                    <StyledProductDescText>
                      {t('category')}&nbsp;:&nbsp;{' '}
                      <Typography fontSize={12} component="span" fontWeight={'700'}>
                        {productInfo?.category || 'N/A'}
                      </Typography>
                    </StyledProductDescText>
                    <StyledProductDescText>
                      {t('composition')}&nbsp;:&nbsp;{' '}
                      <Typography fontSize={12} component="span" fontWeight="700">
                        {productInfo?.composition?.value
                          ? Array.isArray(productInfo?.composition?.value)
                            ? productInfo?.composition?.value?.map((ele) => ele.name).join(', ')
                            : productInfo?.composition?.value
                          : 'N/A'}
                      </Typography>
                    </StyledProductDescText>

                    <StyledProductDescText>
                      {t('sku_number')}&nbsp;:&nbsp;
                      <Typography fontSize={12} component="span" fontWeight={'700'}>
                        {productInfo?.qrId || 'N/A'}
                      </Typography>
                    </StyledProductDescText>

                    <StyledProductDescText>
                      {t('collection')}&nbsp;:&nbsp;
                      <Typography fontSize={12} component="span" fontWeight={'700'}>
                        {productInfo?.collectionName || 'N/A'}
                      </Typography>
                    </StyledProductDescText>
                  </Stack>
                </Stack>
              </StyledProductCard>
            </Grid>
            <Grid item md={8}>
              <Grid container spacing={6}>
                {data?.result?.slice(0, 4)?.map((ele, index) => {
                    const cleanedType = ele?.type?.trim().replace(/,$/, '');
                  return (
                    <Grid item key={index} xs={12} sm={12} md={6}>
                      <StyledPaper>
                        <Stack width={'100%'} alignItems={'flex-start'}>
                          {toggleBtn && editIndex === index ? (
                            <CommonSelect
                              width="120px"
                              options={indicatorsType}
                              value={ObjIndicatorsType[editedValue?.indicator] || editedValue?.indicator || ele?.indicator}
                              handleChange={(e) => setEditedValue({ ...editedValue, indicator: e.target.value })}
                              sx={{ height: 24, width: '100%' }}
                            />
                          ) : (
                            <Chip label={ele?.indicator?.charAt(0)?.toUpperCase() + ele?.indicator?.slice(1)?.toLowerCase()} size="small" sx={{ backgroundColor: chip_indicator[ele?.indicator?.charAt(0)?.toUpperCase() + ele?.indicator?.slice(1)?.toLowerCase()], color: '#fff' }} />
                          )}
                        </Stack>
                        <Stack direction={'row'} alignItems={'flex-end'} spacing={2} mt={user?.userType === 'SYSTEM' ? -2.9 : 0} pl={'128px'}>
                          {user?.userType === 'SYSTEM' && (
                            <>
                              <Tooltip title={t('edit')} arrow placement="top">
                                <span>
                                  <FaEdit
                                    style={{ fontSize: '20px', color: '#0E1090', cursor: 'pointer' }}
                                    onClick={() => {
                                      handleEdit(
                                        'quantity',
                                        ele?.quantity || editedValue?.quantity,
                                        ele?.unit || editedValue?.unit,
                                        ele?.type || editedValue?.type,
                                        ele?.indicator || editedValue?.indicator,
                                        index
                                      );
                                    }}
                                  />
                                </span>
                              </Tooltip>
                              <Tooltip title={t('save')} arrow placement="top">
                                <span>
                                  <FaCheckCircle style={{ fontSize: '20px', color: '#545454', cursor: 'pointer' }} onClick={handleCircle} />
                                </span>
                              </Tooltip>
                              <Tooltip title={t('delete')} arrow placement="top">
                                <span>
                                  <IoMdCloseCircle style={{ fontSize: '22px', color: '#F24822',cursor: 'pointer' }} onClick={() => handleOpenDeleteDialog(index)} />
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </Stack>
                        <Stack width={'100%'} my={2} direction={'row'} spacing={4} justifyContent={'center'} alignItems={'center'}>
                          <Box width={'45%'} display={'flex'} sx={{ gap: '8px' }}>
                            <img src={lcaIndicatorsSvg[cleanedType] || vegan} alt="co2" height={58} width={58} />
                          </Box>
                          <Stack alignItems={'center'}>
                            <Box width={'100px'} display={'flex'} justifyContent={'center'}>
                              {toggleBtn && editIndex === index ? (
                                <CommonInput value={editedValue?.quantity || ele?.quantity} handleChange={(e) => setEditedValue({ ...editedValue, quantity: e.target.value })} sx={{ width: '100%' }} />
                              ) : (
                                <Typography variant="h5" fontWeight={'700'}>
                                  <Typography fontWeight="bold">{formatQuantity(ele?.quantity)}</Typography>
                                </Typography>
                              )}
                            </Box>
                            <Box width={'100px'} display={'flex'} justifyContent={'center'}>
                              {toggleBtn && editIndex === index ? (
                                <CommonInput value={editedValue?.unit || ele?.unit} handleChange={(e) => setEditedValue({ ...editedValue, unit: e.target.value })} sx={{ width: '100%' }} />
                              ) : (
                                <Typography textTransform={'uppercase'} fontSize={12} fontWeight={'700'}>
                                  {ele?.unit}
                                </Typography>
                              )}
                            </Box>
                            <Box width={'125px'} display={'flex'} justifyContent={'center'} alignItems={'center'} mr={2.8}>
                              {toggleBtn && editIndex === index ? (
                                <CommonSelect
                                  width="120px"
                                  options={selectLcaIndicatorsType}
                                  value={lcaIndicatorsType[editedValue?.type] || cleanedType?.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()) || editedValue?.type || cleanedType}
                                  handleChange={(e) => setEditedValue({ ...editedValue, type: e.target.value })}
                                  sx={{ height: 24, width: '120px' }}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        width: '120px',
                                      },
                                    },
                                  }}
                                />
                              ) : (
                                <Tooltip title={<span /* style={{ textTransform: 'uppercase' }} */>{lcaIndicatorsType[cleanedType] || cleanedType?.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()) || cleanedType || ele?.title}</span>}>
                                  <Typography
                                    // textTransform="uppercase"
                                    variant="subtitle2"
                                    sx={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      maxWidth: '120px',
                                    }}
                                  >
                                    {lcaIndicatorsType[cleanedType]?.includes("Climate")
                                      ? `${lcaIndicatorsType[cleanedType]?.slice(0, 14)}...`
                                      : lcaIndicatorsType[cleanedType] || cleanedType?.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()) || cleanedType || ele?.title
                                    }
                                  </Typography>
                                </Tooltip>
                              )}
                            </Box>
                          </Stack>
                        </Stack>
                      </StyledPaper>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Box>
            <StyledList isbgColor={'#0E1090'}>
              <Box width={'35%'}>
                <StyledText>{t('lca_type')}</StyledText>
              </Box>
              <Box width={'20%'} display={'flex'} justifyContent={'center'}>
                <StyledText>{t('qty')}</StyledText>
              </Box>
              <Box width={'15%'} display={'flex'} justifyContent={'center'}>
                <StyledText>{t('unit')}</StyledText>
              </Box>
              <Box width={'15%'} display={'flex'} justifyContent={'center'}>
                <StyledText>{t('indicator')}</StyledText>
              </Box>
              <Box width={'15%'} display={'flex'} justifyContent={'center'}>
                <StyledText>{t('action')}</StyledText>
              </Box>
            </StyledList>
            {loading ? (
              <Box mt={4} display={'flex'} justifyContent={'center'}>
                <CircularProgress size={18} />
              </Box>
            ) : (
              <>
                {data?.result?.slice(4)?.length > 0 ? (
                  <>
                    {data?.result?.slice(4)?.map((ele, index) => {
                        const cleanedType = ele?.type?.trim().replace(/,$/, '');
                      return (
                        <Stack key={index}>
                          <StyledList isbgColor={'#ffffff'}>
                            <Box width={'35%'} display={'flex'} gap={2} alignItems={'center'}>
                              <img src={lcaIndicatorsSvg[cleanedType] || vegan} alt="co2" height={32} width={32} />
                              {toggleBtn && editIndex === index + 4 ? (
                                <CommonSelect
                                  width="120px" 
                                  options={selectLcaIndicatorsType}
                                  value={lcaIndicatorsType[editedValue?.type] || cleanedType?.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())  || editedValue?.type || cleanedType}
                                  handleChange={(e) => setEditedValue({ ...editedValue, type: e.target.value })}
                                />
                              ) : (
                                <Typography fontSize={12} /* textTransform={'uppercase'} */>{lcaIndicatorsType[cleanedType] || cleanedType?.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()) || cleanedType}</Typography>
                              )}
                            </Box>
                            <Box width={'20%'} display={'flex'} justifyContent={'center'}>
                              {toggleBtn && editIndex === index + 4 ? (
                                <CommonInput value={editedValue?.quantity || ele?.quantity} handleChange={(e) => setEditedValue({ ...editedValue, quantity: e.target.value })} sx={{ width: '80px' }} />
                              ) : (
                                <Typography fontWeight="bold">{formatQuantity(ele?.quantity)}</Typography>
                              )}
                            </Box>
                            <Box width={'15%'} display={'flex'} justifyContent={'center'}>
                              {toggleBtn && editIndex === index + 4 ? (
                                <CommonInput value={editedValue?.unit || ele?.unit} handleChange={(e) => setEditedValue({ ...editedValue, unit: e.target.value })} sx={{ width: '60px' }} />
                              ) : (
                                <Typography textTransform={'uppercase'} fontSize={12} fontWeight={'bold'}>
                                  {ele?.unit}
                                </Typography>
                              )}
                            </Box>
                            <Box width={'15%'} display={'flex'} justifyContent={'center'}>
                              {toggleBtn && editIndex === index + 4 ? (
                                <CommonSelect
                                  width="120px"
                                  options={indicatorsType}
                                  value={ObjIndicatorsType[editedValue?.indicator] || editedValue?.indicator || ele?.indicator}
                                  handleChange={(e) => setEditedValue({ ...editedValue, indicator: e.target.value })}
                                />
                              ) : (
                                <Chip label={ele?.indicator?.charAt(0)?.toUpperCase() + ele?.indicator?.slice(1)?.toLowerCase()} size="small" sx={{ backgroundColor: chip_indicator[ele?.indicator?.charAt(0)?.toUpperCase() + ele?.indicator?.slice(1)?.toLowerCase()], color: '#fff' }} />
                              )}
                            </Box>
                            <Stack direction={'row'} alignItems={'center'} spacing={2}>
                              <FaEdit
                                style={{ fontSize: '20px', color: '#0E1090' }}
                                onClick={() => {
                                  handleEdit('quantity', editedValue?.quantity || ele?.quantity, editedValue?.unit || ele?.unit, editedValue?.type || ele?.type, editedValue?.indicator || ele?.indicator, index + 4);
                                }}
                              />
                              <FaCheckCircle style={{ fontSize: '20px', color: '#545454' }} onClick={handleCircle} />
                              <IoMdCloseCircle style={{ fontSize: '26px', color: '#F24822' }} onClick={() => handleDelete(index + 4)} />
                            </Stack>
                          </StyledList>
                        </Stack>
                      );
                    })}
                    <Stack mt={2} alignItems={'center'} width={'100%'} onClick={handleSaveEdit}>
                      <StyledButton variant="contained" isBgColor="#0E1090">
                        Update Result
                      </StyledButton>
                    </Stack>
                  </>
                ) : (
                  <Typography textAlign={'center'} mt={2} fontSize={20}>
                    No Data Found
                  </Typography>
                )}{' '}
              </>
            )}
          </Box>
        </Box>
      </Paper>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
       <AlertDialog
          btnName={t('delete')}
          title={t('delete_result')}
          description={t('are_you_sure_you_want_to_delete_this_Result')}
          open={openDeleteDialog}
          handleClick={() => handleDelete(deletedId)}
          handleCloseDialog={handleCloseDialog}
      />
    </Dialog>
  );
};

export default ProductResultsModal;
