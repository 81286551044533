import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';

const initialState = {
  products: [],
  productLoading: false,
  error: null,
};

export const fetchProducts = createAsyncThunk('product-engine/products', async (_, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.product}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
      },
    });
    console.log(response.data.data);
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});
export const fetchFilterProductsCollection = createAsyncThunk('product-engine/products/filter-collection', async ({ term, page, itemsPerPage }, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.product}/?collection=${term}&page=${page}&limit=${itemsPerPage}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
      },
    });
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});
export const fetchFilterProductsIsArchive = createAsyncThunk('product-engine/isArchived', async ({ term, page, itemsPerPage }, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.product}/?isArchived=${term === 'Yes' ? true : false}&page=${page}&limit=${itemsPerPage}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
      },
    });
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const fetchFilterProductsQRId = createAsyncThunk('product-engine/products/filter-qr', async ({ term, page, itemsPerPage }, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.product}/?qr=${term === 'Yes' ? true : false}&page=${page}&limit=${itemsPerPage}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
      },
    });
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});
export const fetchSortProducts = createAsyncThunk('product-engine/products/sort', async ({ term, page, itemsPerPage }, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.product}/?orderBy=asc&sortBy=${term}&page=${page}&limit=${itemsPerPage}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
      },
    });
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});
export const fetchSearchProducts = createAsyncThunk('product-engine/products/search', async ({ term }, { rejectWithValue }) => {
  try {
    const encodedTerm = encodeURIComponent(term); 
    const response = await httpService.get(`${RestUrlsConstants.product}/?query=${encodedTerm}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
      },
    });
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const fetchFilterProductsIsSelected = createAsyncThunk('product-engine/fetchFilterProductsIsSelected', async ({ value, claimId, page, itemsPerPage }, { rejectWithValue }) => {
  try {
    const queryParam = value === 'Yes' ? `claimId=${claimId}` : `noClaimId=${claimId}`;
    const url = `${RestUrlsConstants.product}/?${queryParam}&page=${page}&limit=${itemsPerPage}`;

    const response = await httpService.get(url, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
      },
    });

    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data || error.message);
  }
});

export const fetchSupplierProductFilter = createAsyncThunk('suppliers/fetchSupplierFilterSupplier', async ({ page, itemsPerPage, filterValue, claimId }, { rejectWithValue }) => {
  try {
    const queryParams = Object.entries(filterValue)
      .filter(([key, value]) => value !== undefined && value !== null && value !== '')
      .map(([key, value]) => {
        if (key === 'isSelected') {
          return value === 'Yes' ? `claimId=${claimId}` : value === 'No' ? `noClaimId=${claimId}` : '';
        }
        if (value === 'Yes' || value === true) return `${key}=true`;
        if (value === 'No' || value === false) return `${key}=false`;

        return `${key}=${value}`;
      })
      .filter(Boolean);
    const queryString = queryParams.length ? `&${queryParams.join('&')}` : '';
    const url = `${RestUrlsConstants.product}/?page=${page}&limit=${itemsPerPage}${queryString}`;

    const response = await httpService.get(url, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
        'Content-Type': 'application/json',
      },
    });

    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data || error.message);
  }
});

const productsSlice = createSlice({
  name: 'productEngine',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.productLoading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.productLoading = false;
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.productLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchFilterProductsCollection.pending, (state) => {
        state.productLoading = true;
        state.error = null;
      })
      .addCase(fetchFilterProductsCollection.fulfilled, (state, action) => {
        state.productLoading = false;
        state.products = action.payload;
      })
      .addCase(fetchFilterProductsCollection.rejected, (state, action) => {
        state.productLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchFilterProductsIsArchive.pending, (state) => {
        state.productLoading = true;
        state.error = null;
      })
      .addCase(fetchFilterProductsIsArchive.fulfilled, (state, action) => {
        state.productLoading = false;
        state.products = action.payload;
      })
      .addCase(fetchFilterProductsIsArchive.rejected, (state, action) => {
        state.productLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchFilterProductsQRId.pending, (state) => {
        state.productLoading = true;
        state.error = null;
      })
      .addCase(fetchFilterProductsQRId.fulfilled, (state, action) => {
        state.productLoading = false;
        state.products = action.payload;
      })
      .addCase(fetchFilterProductsQRId.rejected, (state, action) => {
        state.productLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchSortProducts.pending, (state) => {
        state.productLoading = true;
        state.error = null;
      })
      .addCase(fetchSortProducts.fulfilled, (state, action) => {
        state.productLoading = false;
        state.products = action.payload;
      })
      .addCase(fetchSortProducts.rejected, (state, action) => {
        state.productLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchSearchProducts.pending, (state) => {
        state.productLoading = true;
        state.error = null;
      })
      .addCase(fetchSearchProducts.fulfilled, (state, action) => {
        state.productLoading = false;
        state.products = action.payload;
      })
      .addCase(fetchSearchProducts.rejected, (state, action) => {
        state.productLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchFilterProductsIsSelected.pending, (state) => {
        state.productLoading = true;
        state.error = null;
      })
      .addCase(fetchFilterProductsIsSelected.fulfilled, (state, action) => {
        state.productLoading = false;
        state.products = action.payload;
      })
      .addCase(fetchFilterProductsIsSelected.rejected, (state, action) => {
        state.productLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierProductFilter.pending, (state) => {
        state.productLoading = true;
        state.error = null;
      })
      .addCase(fetchSupplierProductFilter.fulfilled, (state, action) => {
        state.productLoading = false;
        state.products = action.payload;
      })
      .addCase(fetchSupplierProductFilter.rejected, (state, action) => {
        state.productLoading = false;
        state.error = action.payload;
      });
  },
});

export default productsSlice.reducer;
