import React, { useState, useEffect } from 'react';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { CommonButton } from './utils/CommonButton';
import SearchIcon from '@mui/icons-material/Search';
import AlertDialog from './utils/AlertDialog';
import { useTranslation } from 'react-i18next';
import Loader from './utils/loader';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const User = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [brandId, setBrandId] = useState('');
  const [roleId, setRoleId] = useState('');
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [brands, setBrands] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [updateMode, setUpdateMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [orderBy, setOrderBy] = useState('asc');

  useEffect(() => {
    fetchBrands();
    fetchRoles();
  }, []);
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchBrands = async () => {
    try {
      const response = await httpService.get(RestUrlsConstants.brandUrl, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      setBrands(response.data.data);
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await httpService.get(RestUrlsConstants.roleUrl, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      setRoles(response.data.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const fetchUsers = async () => {
    setUserLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.userUrl}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      setUsers(response.data.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setUserLoading(false);
    }
  };
  const searchUsers = async (term) => {
    setUserLoading(true);
    try {
      const encodedTerm = encodeURIComponent(term);
      const response = await httpService.get(`${RestUrlsConstants.userUrl}?query=${encodedTerm}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      setUsers(response.data.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setUserLoading(false);
    }
  };
  const handleSort = async () => {
    const newOrderBy = orderBy === 'asc' ? 'desc' : 'asc';
    setOrderBy(newOrderBy);
    setLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.userUrl}?sortBy=name&orderBy=${newOrderBy}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      setUsers(response.data.data);
      setShowTable(true);
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!firstName.trim()) errors.firstName = 'First Name is required';
    if (!lastName.trim()) errors.lastName = 'Last Name is required';
    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
    }
    if (!updateMode && !password.trim()) errors.password = 'Password is required';
    if (!roleId) errors.roleId = 'Role is required';
    if (!brandId) errors.brandId = 'Brand is required';

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const createUser = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      setError(null);

      const user = {
        firstName,
        lastName,
        email,
        password,
        roleId,
        brandId,
      };

      const response = await httpService.post(RestUrlsConstants.userUrl, user, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response.status === 200) {
        handleClose();
      }

      if (!response.data) {
        throw new Error('Failed to create user');
      }

      await fetchUsers();
      setShowForm(false);
      setFirstName('');
      setLastName('');
      setPassword('');
      setEmail('');
      setBrandId('');
      setRoleId('');
      setShowTable(true);
    } catch (error) {
      setError(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      setError(null);

      const updatedUser = {
        firstName,
        lastName,
        email,
        roleId,
        brandId,
      };

      if (password.trim() !== '') {
        updatedUser.password = password;
      }

      const res = await httpService.put(`${RestUrlsConstants.userUrl}${selectedUserId}`, updatedUser, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (res.status === 200) {
        handleClose();
      }

      await fetchUsers();
      setUpdateMode(false);
      setShowForm(false);
      setShowTable(true);
      setFirstName('');
      setLastName('');
      setPassword('');
      setEmail('');
      setBrandId('');
      setRoleId('');
      setSelectedUserId(null);
    } catch (error) {
      if (error?.response?.data?.message?.includes('duplicate key error')) {
        setError('A user with this email already exists.');
      } else {
        setError(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUser = (userId) => {
    const selectedUser = users.find((user) => user.id === userId);
    setFirstName(selectedUser.firstName);
    setLastName(selectedUser.lastName);
    setEmail(selectedUser.email);
    setPassword('');
    setBrandId(selectedUser.brand.id);
    setRoleId(selectedUser.role.id);
    setSelectedUserId(userId);
    setUpdateMode(true);
    setShowForm(true);
    setShowTable(false);
    handleOpen();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (updateMode) {
      await updateUser();
    } else {
      await createUser();
    }
  };

  const deleteUser = async (userId) => {
    try {
      setLoading(true);
      setError(null);

      await httpService.delete(`${RestUrlsConstants.userUrl}${userId}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });

      await fetchUsers();
    } catch (error) {
      setError(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
    setUpdateMode(false);
    setShowTable(!showTable);
    handleOpen();
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.trim() === '') {
      fetchUsers();
    } else {
      searchUsers(term);
    }
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setFirstName('');
    setLastName('');
    setPassword('');
    setEmail('');
    setBrandId('');
    setRoleId('');
  };

  const handleOpenDialog = (userId) => {
    setSelectedUserId(userId);
    setOpenDialog(true);
  };
  const handleDeleteRoles = async () => {
    await deleteUser(selectedUserId);
    setOpenDialog(false);
  };

  const handleCloseDialog = async () => {
    setOpenDialog(false);
    setSelectedUserId(null);
  };

  const columns = [t('first_name'), t('last_name'), t('email'), t('brand_name'), t('role'), t('actions')];
  return (
    <>
      <Card className="example" sx={{ borderRadius: '20px' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <CommonButton buttonName={t('add_users')} handleClick={toggleFormVisibility} />
            <TextField
              size="small"
              variant="outlined"
              placeholder={t('search')}
              value={searchTerm}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                },
              }}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Dialog open={modalOpen} onClose={handleClose}>
            <DialogContent className="example" sx={{ width: '400px' }}>
              <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                {updateMode ? t('update_user') : t('add_user')}
              </Typography>
              {error && <div className="alert alert-danger">{error}</div>}
              <form onSubmit={handleSubmit}>
                <Grid mt={1} container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('first_name')}:</InputLabel>
                    <TextField
                      sx={{ mt: 2 }}
                      fullWidth
                      size="small"
                      type="text"
                      id="firstName"
                      variant="standard"
                      placeholder={t('first_name')}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    {validationErrors.firstName && (
                      <span className="text-danger" style={{ fontSize: '12px' }}>
                        {validationErrors.firstName}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('last_name')}:</InputLabel>
                    <TextField
                      sx={{ mt: 2 }}
                      fullWidth
                      size="small"
                      type="text"
                      id="lastName"
                      placeholder={t('last_name')}
                      value={lastName}
                      variant="standard"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    {validationErrors.lastName && (
                      <span className="text-danger" style={{ fontSize: '12px' }}>
                        {validationErrors.lastName}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('email_address')}:</InputLabel>
                    <TextField
                      sx={{ mt: 2 }}
                      fullWidth
                      size="small"
                      variant="standard"
                      type="email"
                      id="email"
                      placeholder={t('email_address')}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {validationErrors.email && (
                      <span className="text-danger" style={{ fontSize: '12px' }}>
                        {validationErrors.email}
                      </span>
                    )}
                  </Grid>
                  {!updateMode && (
                    <Grid item xs={12}>
                      <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('password')}:</InputLabel>
                      <TextField
                        fullWidth
                        variant="standard"
                        size="small"
                        type="password"
                        id="password"
                        placeholder={t('password')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ mt: 2 }}
                      />
                      {validationErrors.password && (
                        <div className="text-danger" style={{ fontSize: '12px' }}>
                          {validationErrors.password}
                        </div>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('select_role')}:</InputLabel>
                    <Select sx={{ mt: 2 }} variant="standard" fullWidth size="small" id="roleId" value={roleId} onChange={(e) => setRoleId(e.target.value)}>
                      {roles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {validationErrors.roleId && (
                      <span className="text-danger" style={{ fontSize: '12px' }}>
                        {validationErrors.roleId}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('select_brand')}:</InputLabel>
                    <Select sx={{ mt: 2 }} variant="standard" fullWidth size="small" id="brandId" value={brandId} onChange={(e) => setBrandId(e.target.value)}>
                      {brands.map((brand) => (
                        <MenuItem key={brand.id} value={brand.id}>
                          {brand.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {validationErrors.brandId && (
                      <span className="text-danger" style={{ fontSize: '12px' }}>
                        {validationErrors.brandId}
                      </span>
                    )}
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Button
                    sx={{ background: '#101010', ':hover': { background: '#101010' }, borderRadius: '25px', textTransform: 'capitalize' }}
                    fullWidth
                    size="small"
                    type="submit"
                    variant="contained"
                    disabled={loading}
                  >
                    {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : updateMode ? t('update_user') : t('create_user_account')}
                  </Button>
                  <Button
                    fullWidth
                    sx={{ mt: 1, background: 'gray', ':hover': { background: '#535353' }, borderRadius: '25px', textTransform: 'capitalize' }}
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setShowForm(false);
                      setShowTable(true);
                      setError(null);
                      handleClose();
                    }}
                  >
                    {t('cancel')}
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </Dialog>
          <Box>
            <TableContainer>
              <Typography sx={{ fontWeight: 'bold', mt: 4, fontSize: '24px' }}>{t('users')} </Typography>
              <Table sx={{ cursor: 'pointer' }}>
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }} key={index}>
                        {index === 0 ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {column}
                            <IconButton onClick={handleSort}>{orderBy === 'asc' ? <ArrowUpwardIcon sx={{ color: '#000' }} /> : <ArrowDownwardIcon sx={{ color: '#000' }} />}</IconButton>
                          </Box>
                        ) : (
                          column
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userLoading ? (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {users?.length > 0 ? (
                        users.map((user, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{user.firstName}</TableCell>
                            <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{user.lastName}</TableCell>
                            <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{user.email}</TableCell>
                            <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{user?.brand?.name}</TableCell>
                            <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{user?.role?.name}</TableCell>
                            <TableCell>
                              <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                                <FontAwesomeIcon style={{ color: '#0e1090' }} icon={faPenToSquare} onClick={() => handleUpdateUser(user.id)} />
                                <FontAwesomeIcon style={{ color: '#c82333' }} icon={faTrashCan} onClick={() => handleOpenDialog(user.id)} />
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6} sx={{ textAlign: 'center', fontWeight: '700', fontSize: '12px' }}>
                            No records found
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
              <AlertDialog
                btnName={'Delete'}
                title={'Delete User'}
                description={t('are_you_sure_you_want_to_delete_this_user')}
                open={openDialog}
                handleClick={handleDeleteRoles}
                handleCloseDialog={handleCloseDialog}
              />
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default User;
