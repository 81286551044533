import React, { useState, useEffect } from 'react';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { CommonButton } from './utils/CommonButton';
import { Modal } from 'react-bootstrap';
import AlertDialog from './utils/AlertDialog';
import { useTranslation } from 'react-i18next';
import Loader from './utils/loader';
import moment from 'moment';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import LanguageSelector from './utils/LanguageSelector';
import BrandLanguageSelector from './utils/BrandLanguageSelector';
import useCheckOnlineStatus from '../hooks/useOnlineCheckStatus';
import Auth from '../hooks/auth.hook';

const Brand = () => {
  const { douthentiction } = Auth();
  const { t } = useTranslation();
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const [brands, setBrands] = useState([]);
  const [brandName, setBrandName] = useState('');
  const [contractStartDate, setContractStartDate] = useState('');
  const [contractEndDate, setContractEndDate] = useState('');
  const [maxProducts, setMaxProducts] = useState(0);
  const [maxUsers, setMaxUsers] = useState(0);
  const [contractFiles, setContractFiles] = useState([]);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [updateMode, setUpdateMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [brandId, setBrandId] = useState(null);
  const [orderBy, setOrderBy] = useState('asc');
  const [features, setFeatures] = useState('');
  const [maxLcaProduct, setMaxLcaProduct] = useState('');
  const [logoUrl, setLogoUrl] = useState(null);
  const [contractFileUrl, setContractFileUrl] = useState([]);
  const [multipleSelectedValues, setMultipleSelectedValues] = useState([]);
  const [singleSelectedValue, setSingleSelectedValue] = useState('');
  const filteredLanguages = multipleSelectedValues.length > 0 ? multipleSelectedValues : [];

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    if (!checkOnlineStatus()) return;
    setLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.brandUrl}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });

      if (response?.data?.status) {
        setBrands(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    } finally {
      setLoading(false);
    }
  };
  const searchBrands = async (term) => {
    if (!checkOnlineStatus()) return;
    setLoading(true);
    try {
      const encodedTerm = encodeURIComponent(term);
      const response = await httpService.get(`${RestUrlsConstants.brandUrl}?query=${encodedTerm}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response?.data?.status) {
        setBrands(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = async () => {
    const newOrderBy = orderBy === 'asc' ? 'desc' : 'asc';
    setOrderBy(newOrderBy);
    setLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.brandUrl}?sortBy=name&orderBy=${newOrderBy}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response?.data?.status) {
        setBrands(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    } finally {
      setLoading(false);
    }
  };

  const validateField = (name, value) => {
    const errors = {};

    if (name === 'brandName' && !value) {
      errors.brandName = t('brand_name_required');
    }

    if (name === 'contractFiles') {
      const isValidPDF = Array.from(value).every((file) => file.type === 'application/pdf');
      if (!isValidPDF) {
        errors.contractFiles = t('only_PDF_files_are_allowed');
      }
    }

    if (name === 'logo') {
      const isValidImage = value && ['image/png', 'image/jpg', 'image/jpeg'].includes(value.type);
      if (!isValidImage) {
        errors.logo = t('only_image_files_PNG_JPEG_JPG_are_allowed');
      }
    }

    return errors;
  };

  const handleChange = (name, value) => {
    switch (name) {
      case 'brandName':
        setBrandName(value);
        break;
      case 'contractStartDate':
        setContractStartDate(value);
        break;
      case 'contractEndDate':
        setContractEndDate(value);
        break;
      case 'maxProducts':
        setMaxProducts(value);
        break;
      case 'maxUsers':
        setMaxUsers(value);
        break;
      case 'maxLCAProducts':
        setMaxLcaProduct(value);
        break;
      case 'features':
        setFeatures(value);
        break;
      case 'contractFiles':
        setContractFiles(value);
        break;
      case 'logo':
        setLogo(value);
        break;
      default:
        break;
    }

    const errors = { ...formErrors };
    const newErrors = validateField(name, value);
    delete errors[name];
    setFormErrors({ ...errors, ...newErrors });
  };

  // const handleFileChange = (e) => {
  //   const files = e.target.files;
  //   handleChange('contractFiles', files);
  // };
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    handleChange('contractFiles', files);
    // setContractFiles(files);
    setContractFileUrl(files);
  };

  const handleRemoveFile = (fileToRemove) => {
    const updatedFiles = contractFileUrl.filter((file) => file !== fileToRemove);
    handleChange('contractFiles', updatedFiles);
    // setContractFiles(updatedFiles);
    setContractFileUrl(updatedFiles);
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newLogoUrl = URL.createObjectURL(file);
      setLogoUrl(newLogoUrl);
      handleChange('logo', file);
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!brandName) {
      errors.brandName = t('brand_name_required');
    }

    if (contractFiles.length > 0) {
      const isValidPDF = Array.from(contractFiles).every((file) => file.type === 'application/pdf');
      if (!isValidPDF) {
        errors.contractFiles = t('only_PDF_files_are_allowed');
      }
    }

    if (logo) {
      const isValidImage = ['image/png', 'image/jpg', 'image/jpeg'].includes(logo.type);
      if (!isValidImage) {
        errors.logo = t('only_image_files_PNG_JPEG_JPG_are_allowed');
      }
    }

    return errors;
  };

  const createBrand = async () => {
    if (!checkOnlineStatus()) return;

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', brandName);

      if (contractStartDate) {
        formData.append('contractStartDate', contractStartDate.slice(0, 10));
      }

      if (contractEndDate) {
        formData.append('contractEndDate', contractEndDate.slice(0, 10));
      }

      if (maxProducts) {
        formData.append('maxProducts', maxProducts);
      }

      if (maxUsers) {
        formData.append('maxUsers', maxUsers);
      }

      if (maxLcaProduct) {
        formData.append('maxLCAProducts', maxLcaProduct);
      }
      if (features.trim() !== '') {
        formData.append('features', features);
      }
      if (multipleSelectedValues.length > 0) {
        multipleSelectedValues.forEach((language, index) => {
          formData.append(`languages[${index}]`, language);
        });
      }
      if (singleSelectedValue) {
        formData.append('defaultLanguage', singleSelectedValue);
      }
      // let newarry = [];
      // newarry = features?.split(',');
      // if (features !== '') {
      //   for (let i = 0; i < newarry?.length; i++) {
      //     formData.append(`features[${i}]`, newarry[i]);
      //   }
      // }

      if (contractFiles && contractFiles.length > 0) {
        for (let i = 0; i < contractFiles.length; i++) {
          formData.append('contractFiles', contractFiles[i]);
        }
      }

      if (logo) {
        formData.append('logo', logo);
      }

      const response = await httpService.post(RestUrlsConstants.brandUrl, formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        fetchBrands();
        setShowForm(false);
        resetFormFields();
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    } finally {
      setLoading(false);
    }
  };

  const updateBrand = async () => {
    if (!checkOnlineStatus()) return;

    try {
      setLoading(true);
      const formData = new FormData();

      formData.append('name', brandName);
      formData.append('contractStartDate', contractStartDate.slice(0, 10));
      formData.append('contractEndDate', contractEndDate.slice(0, 10));
      formData.append('maxProducts', maxProducts);
      formData.append('maxUsers', maxUsers);
      formData.append('maxLCAProducts', maxLcaProduct);
      if (features.trim() !== '') {
        formData.append('features', features);
      }

      if (contractFiles.length) {
        for (let i = 0; i < contractFiles.length; i++) {
          formData.append('contractFiles', contractFiles[i]);
        }
      }

      if (logo) {
        formData.append('logo', logo);
      }

      if (multipleSelectedValues.length > 0) {
        multipleSelectedValues.forEach((language, index) => {
          formData.append(`languages[${index}]`, language);
        });
      }
      if (singleSelectedValue) {
        formData.append('defaultLanguage', singleSelectedValue);
      }
      const response = await httpService.put(`${RestUrlsConstants.brandUrl}${selectedBrandId}`, formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        fetchBrands();
        setUpdateMode(false);
        setShowForm(false);
        resetFormFields();
        setSelectedBrandId(null);
        setSearchTerm('');
      }
    } catch (error) {
      setSnackBar({ open: true, message: error?.response?.data?.message });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteBrand = async (brandId) => {
    if (!checkOnlineStatus()) return;

    try {
      setLoading(true);
      const response = await httpService.delete(`${RestUrlsConstants.brandUrl}${brandId}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        fetchBrands();
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateBrand = async (brandId) => {
    if (!checkOnlineStatus()) return;
    try {
      const response = await httpService.get(`${RestUrlsConstants.brandUrl}/${brandId}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response?.data?.status) {
        setUpdateMode(true);
        setBrandName(response.data.data.name);
        setContractStartDate(response.data.data.contractStartDate);
        setContractEndDate(response.data.data.contractEndDate);
        setMaxProducts(response.data.data.maxProducts);
        setMaxUsers(response.data.data.maxUsers);
        setMaxLcaProduct(response.data.data.maxLCAProducts);
        setFeatures(response.data.data.features.join(','));
        setContractFiles([]);
        setContractFileUrl(response.data.data.contractFiles);
        setLogo(null);
        setLogoUrl(response.data.data.logo);
        setSelectedBrandId(brandId);
        setSingleSelectedValue(response.data.data.defaultLanguage);
        setMultipleSelectedValues(response.data.data.languages);
        setShowForm(true);
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    if (!checkOnlineStatus()) return;

    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    if (updateMode) {
      updateBrand();
    } else {
      createBrand();
    }
  };

  const resetFormFields = () => {
    setBrandName('');
    setContractStartDate('');
    setContractEndDate('');
    setMaxProducts('');
    setMaxUsers('');
    setFeatures('');
    setContractFiles([]);
    setLogo(null);
    setFormErrors({});
    setLogoUrl(null);
    setContractFileUrl([]);
    setMultipleSelectedValues([]);
    setSingleSelectedValue('');
    setMaxLcaProduct('');
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
    setUpdateMode(false);
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.trim() === '') {
      fetchBrands();
    } else {
      searchBrands(term);
    }
  };

  const handleCloseModal = () => {
    if (searchTerm.trim() !== '') {
      setSearchTerm('');
      setShowForm(false);
      resetFormFields();
      fetchBrands();
    } else {
      setShowForm(false);
      resetFormFields();
    }
  };

  const handleOpenDialog = (brandId) => {
    setBrandId(brandId);
    setOpenDialog(true);
  };
  const handleDeleteBrand = async () => {
    await deleteBrand(brandId);
    setOpenDialog(false);
  };

  const handleCloseDialog = async () => {
    setOpenDialog(false);
    setBrandId(null);
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const columns = [
    t('brand_name'),
    t('unique_Id'),
    t('contract_start_date'),
    t('contract_end_date'),
    t('max_products'),
    t('max_users'),
    t('max_lca_products'),
    t('features'),
    t('contract_files'),
    t('logo'),
    t('actions'),
  ];

  return (
    <>
      <Card className="example" sx={{ borderRadius: '20px', bgcolor: '#fff' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <CommonButton buttonName={t('add_brand')} handleClick={toggleFormVisibility} />
            <TextField
              size="small"
              variant="outlined"
              placeholder={t('search')}
              value={searchTerm}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                },
              }}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Modal className="example" size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={showForm} onHide={handleCloseModal} animation={false}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
              <Modal.Title>{updateMode ? t('update_brand') : t('create_brand')}</Modal.Title>
              <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <BrandLanguageSelector
                  placeHolder="Select-Multi-Languages"
                  multipleSelect={true}
                  multipleSelectedValues={multipleSelectedValues}
                  setMultipleSelectedValues={setMultipleSelectedValues}
                />
                <BrandLanguageSelector
                  placeHolder="Select-Default-language"
                  multipleSelect={false}
                  singleSelectedValue={singleSelectedValue}
                  setSingleSelectedValue={setSingleSelectedValue}
                  disabled={multipleSelectedValues.length === 0}
                  availableLanguages={filteredLanguages}
                />
                <IconButton onClick={handleCloseModal}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Modal.Body>
              <Box>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('brand_name')}:</InputLabel>
                      <TextField
                        fullWidth
                        sx={{ mt: 2 }}
                        required
                        size="small"
                        variant="standard"
                        type="text"
                        id="brandName"
                        placeholder={t('brand_name')}
                        value={brandName}
                        onChange={(e) => {
                          handleChange('brandName', e.target.value);
                        }}
                      />
                      {formErrors.brandName && (
                        <span className="text-danger" style={{ fontSize: '12px' }}>
                          {formErrors.brandName}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('contract_start_date')}:</InputLabel>
                      <TextField
                        InputLabelProps={{
                          shrink: false,
                        }}
                        sx={{ mt: 2 }}
                        placeholder="YYYY-MM-DD"
                        fullWidth
                        required
                        size="small"
                        variant="standard"
                        type="date"
                        id="contractStartDate"
                        value={contractStartDate ? contractStartDate.slice(0, 10) : ''}
                        onChange={(e) => {
                          handleChange('contractStartDate', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('contract_end_date')}:</InputLabel>
                      <TextField
                        sx={{ mt: 2 }}
                        fullWidth
                        required
                        size="small"
                        variant="standard"
                        type="date"
                        id="contractEndDate"
                        value={contractEndDate ? contractEndDate.slice(0, 10) : ''}
                        onChange={(e) => {
                          handleChange('contractEndDate', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('max_products')}:</InputLabel>
                      <TextField
                        sx={{ mt: 2 }}
                        fullWidth
                        required
                        size="small"
                        variant="standard"
                        type="number"
                        inputProps={{ min: 0 }}
                        id="maxProducts"
                        placeholder={t('max_products')}
                        value={maxProducts}
                        onChange={(e) => {
                          handleChange('maxProducts', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('max_users')}:</InputLabel>
                      <TextField
                        sx={{ mt: 2 }}
                        fullWidth
                        required
                        size="small"
                        variant="standard"
                        type="number"
                        inputProps={{ min: 0 }}
                        id="maxUsers"
                        className="form-control"
                        placeholder={t('max_users')}
                        value={maxUsers}
                        onChange={(e) => {
                          handleChange('maxUsers', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('features')}:</InputLabel>
                      <TextField
                        sx={{ mt: 2 }}
                        fullWidth
                        required
                        size="small"
                        variant="standard"
                        type="text"
                        id="features"
                        placeholder={t('features')}
                        value={features}
                        onChange={(e) => {
                          handleChange('features', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('max_lca_products')}:</InputLabel>
                      <TextField
                        sx={{ mt: 2 }}
                        fullWidth
                        required
                        size="small"
                        variant="standard"
                        type="number"
                        id="max_lca_products"
                        placeholder={t('max_lca_products')}
                        value={maxLcaProduct}
                        onChange={(e) => {
                          handleChange('maxLCAProducts', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('contract_files')}:</InputLabel>
                      <input
                        required
                        type="file"
                        id="contractFiles"
                        className="form-control"
                        accept=".pdf"
                        onChange={(e) => {
                          handleFileChange(e);
                        }}
                        multiple
                        style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none', marginTop: '10px' }}
                      />
                      {formErrors.contractFiles && (
                        <span className="text-danger" style={{ fontSize: '12px' }}>
                          {formErrors.contractFiles}
                        </span>
                      )}
                      <Box mt={2} sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        {contractFileUrl?.map((file, index) => {
                          const fileName = typeof file === 'string' ? file?.substring(file?.lastIndexOf('/') + 1) : file.name;
                          return (
                            <Paper key={index} sx={{ height: '50px', width: '50px', borderRadius: '5px', position: 'relative' }}>
                              <Avatar variant="square" sx={{ bgcolor: '#f44336', height: '100%', width: '100%', borderRadius: '5px' }}>
                                <PictureAsPdfIcon />
                              </Avatar>
                              <CloseIcon
                                sx={{
                                  fontSize: '18px',
                                  color: '#fff',
                                  position: 'absolute',
                                  top: -10,
                                  right: -10,
                                  backgroundColor: '#101010',
                                  borderRadius: '10px',
                                  zIndex: 100,
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleRemoveFile(file)}
                              />
                              <Tooltip title={fileName}>
                                <Typography sx={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '10px' }}>
                                  <a className="download1" target="_self" href={typeof file === 'string' ? file : URL.createObjectURL(file)} rel="noopener noreferrer">
                                    {fileName}
                                  </a>
                                </Typography>
                              </Tooltip>
                            </Paper>
                          );
                        })}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('logo')}:</InputLabel>
                      <input
                        required
                        type="file"
                        id="logo"
                        className="form-control"
                        accept="image/jpg,image/png,image/jpeg"
                        onChange={(e) => {
                          handleLogoChange(e);
                        }}
                        style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none', marginTop: '10px' }}
                      />
                      {formErrors.logo && (
                        <span className="text-danger" style={{ fontSize: '12px' }}>
                          {formErrors.logo}
                        </span>
                      )}
                      {logoUrl && (
                        <Paper sx={{ height: '50px', width: '50px', borderRadius: '5px', position: 'relative', mt: 2 }}>
                          <Avatar variant="square" sx={{ height: '100%', width: '100%', borderRadius: '5px' }}>
                            <img src={logoUrl} alt="" height={'100%'} width={'100%'} />
                          </Avatar>
                          <CloseIcon
                            sx={{
                              fontSize: '18px',
                              color: '#fff',
                              position: 'absolute',
                              top: -10,
                              right: -10,
                              backgroundColor: '#101010',
                              borderRadius: '10px',
                              zIndex: 100,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setLogo(null);
                              setLogoUrl(null);
                            }}
                          />
                        </Paper>
                      )}
                    </Grid>
                  </Grid>
                  <Box mt={2} sx={{ display: 'flex', gap: '20px', alignItems: 'end', justifyContent: 'right' }}>
                    <Button
                      onClick={handleSubmit}
                      sx={{ background: '#101010', ':hover': { background: '#101010' }, borderRadius: '25px', textTransform: 'capitalize', px: 3 }}
                      size="small"
                      variant="contained"
                    >
                      {loading ? (
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </>
                      ) : updateMode ? (
                        t('update')
                      ) : (
                        t('submit')
                      )}
                    </Button>
                    <Button
                      size="small"
                      sx={{ background: '#535353', ':hover': { background: 'gray' }, borderRadius: '25px', textTransform: 'capitalize', px: 3 }}
                      onClick={handleCloseModal}
                      variant="contained"
                    >
                      {t('cancel')}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Modal.Body>
          </Modal>
          <Box>
            <TableContainer>
              <Typography sx={{ fontWeight: 'bold', mt: 4, fontSize: '24px' }}>{t('brands')}</Typography>
              <Table sx={{ cursor: 'pointer' }}>
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }} key={index}>
                        {index === 0 ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {column}
                            <IconButton onClick={handleSort}>{orderBy === 'asc' ? <ArrowUpwardIcon sx={{ color: '#000' }} /> : <ArrowDownwardIcon sx={{ color: '#000' }} />}</IconButton>
                          </Box>
                        ) : (
                          column
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {brands?.length > 0 ? (
                        brands.map((brand, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{brand.name}</TableCell>
                            <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{brand.uniqueId}</TableCell>
                            <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{moment(brand.contractStartDate).format('DD-MM-YYYY')}</TableCell>
                            <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{moment(brand.contractEndDate).format('DD-MM-YYYY')}</TableCell>
                            <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{brand.maxProducts}</TableCell>
                            <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{brand.maxUsers}</TableCell>
                            <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{brand.maxLCAProducts}</TableCell>
                            <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{brand.features.join(', ')}</TableCell>
                            <TableCell>
                              {brand.contractFiles.map((file, i) => (
                                <Box sx={{ mb: '2px' }} key={i}>
                                  <a className="download" href={`${file}`} rel="noopener noreferrer">
                                    <Chip sx={{ fontSize: '12px', bgcolor: '#ec895a', color: '#fff' }} size="small" label={t('download')} />
                                  </a>
                                </Box>
                              ))}
                            </TableCell>
                            <TableCell>{brand.logo !== '' && <img src={`${brand.logo}`} alt="" style={{ width: '40px', height: '40px' }} />}</TableCell>
                            <TableCell>
                              <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                                <FontAwesomeIcon style={{ color: '#0e1090' }} icon={faPenToSquare} onClick={() => handleUpdateBrand(brand.id)} />
                                <FontAwesomeIcon style={{ color: '#c82333' }} icon={faTrashCan} onClick={() => handleOpenDialog(brand.id)} />
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={11} sx={{ textAlign: 'center', fontWeight: '700', fontSize: '12px' }}>
                            No records found
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
              <AlertDialog
                btnName={t('delete')}
                title={t('delete_brand')}
                description={t('are_you_sure_you_want_to_delete_this_brand')}
                open={openDialog}
                handleClick={handleDeleteBrand}
                handleCloseDialog={handleCloseDialog}
              />
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};

export default Brand;
