import * as React from 'react';
import Popover from '@mui/material/Popover';
import { IconButton, InputLabel, FormControl, MenuItem, Select, Button, Typography, Box, ListItemText } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import { CountryList } from './CommonList';
import { useDispatch } from 'react-redux';
import { fetchSupplier, fetchSupplierFilterSupplier } from '../../features/supplierSlice';

const FilterOption = ({ label, value, options, onChange }) => (
  <>
    <InputLabel sx={{ fontSize: '14px' }}>{label}</InputLabel>
    <FormControl fullWidth sx={{ mb: 2 }}>
      <Select variant="standard" size="small" value={value} onChange={(e) => onChange(e.target.value)}>
        {options.map((option) => (
          <MenuItem key={option} value={option} sx={{ fontSize: '12px' }}>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </>
);

export default function FilterSupplier(props) {
  const { page, itemsPerPage, claimId,showselected,setPage } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const [filterValues, setFilterValues] = React.useState({
    hasProduct: '',
    isSupplyChain: '',
    isSelected: '',
    country: '',
    sortBy: '',
  });


  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  // const handleFilterChange = (filterType, value) => {
  //   const updatedFilters = { ...filterValues, [filterType]: value };
  //   setFilterValues(updatedFilters);

  //   dispatch(
  //     fetchSupplierFilterSupplier({
  //       page,
  //       itemsPerPage,
  //       filterValue: updatedFilters,
  //       claimId,
  //     })
  //   );

  //   handleClose();
  // };
  const handleFilterChange = (filterType, value) => {
    const updatedFilters = { ...filterValues, [filterType]: value };
  
    if (filterValues[filterType] !== value) {
      setPage(1); 
    }
  
    setFilterValues(updatedFilters); 
    handleClose(); 
  };
  
  React.useEffect(() => {
    const hasFilters = Object.values(filterValues).some(val => val !== '');
    if (hasFilters) {
      dispatch(
        fetchSupplierFilterSupplier({
          page,
          itemsPerPage,
          filterValue: filterValues,
          claimId,
        })
      );
    }
  }, [page, filterValues, dispatch, itemsPerPage, claimId]);
  
  

  const handleClearAll = () => {
    setFilterValues({
      hasProduct: '',
      isSupplyChain: '',
      isSelected: '',
      country: '',
      sortBy: '',
    });
    dispatch(fetchSupplier({ page, itemsPerPage }));
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick} sx={{ bgcolor: '#0e1090', borderRadius: '30px', ':hover': { bgcolor: '#0e1090' } }}>
        <FilterListIcon sx={{ color: '#fff' }} />
      </IconButton>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Box sx={{ width: 300, padding: 2, border: '1px solid #ddd', borderRadius: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
              {t('filter')} <FilterAltIcon sx={{ fontSize: '20px' }} />
            </Typography>
            <Button onClick={handleClearAll} sx={{ fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize', color: '#0e1090' }}>
              {t('clear_all')}
            </Button>
          </Box>

          <FilterOption label={t('has_suppliers_products')} value={filterValues.hasProduct} options={[t('yes'), t('no')]} onChange={(value) => handleFilterChange('hasProduct', value)} />

          <FilterOption label={t('is_in_a_supply_chain')} value={filterValues.isSupplyChain} options={[t('yes'), t('no')]} onChange={(value) => handleFilterChange('isSupplyChain', value)} />

          {showselected && <FilterOption label={t('is_selected')} value={filterValues.isSelected} options={[t('yes'), t('no')]} onChange={(value) => handleFilterChange('isSelected', value)} />}

          <FilterOption label={t('country')} value={filterValues.country} options={CountryList} onChange={(value) => handleFilterChange('country', value)} />

          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
            {t('sort_by')} <SortIcon />
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={filterValues.sortBy} onChange={(e) => handleFilterChange('sortBy', e.target.value)}>
              {[t('name_'), t('date_')].map((sortOption) => (
                <MenuItem key={sortOption} value={sortOption} sx={{ textTransform: 'capitalize' }}>
                  {sortOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Popover>
    </>
  );
}
