import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Snackbar from '@mui/material/Snackbar';
//import icons
import { IoMdArrowBack } from 'react-icons/io';
//relative path imports
import PATH from '../../utils/path';
import AssessmentStep1 from './AssessmentStep1';
import AssessmentStep2 from './AssessmentStep2';
import AssessmentStep3 from './AssessmentStep3';
import AssessmentStep4 from './AssessmentStep4';
// import AssessmentStep5 from './AssessmentStep5';
import useCheckOnlineStatus from '../../../hooks/useOnlineCheckStatus';
import httpService from '../../../service/http.service';
import { RestUrlsConstants } from '../../../constants/rest-urls.constants';
import AssessmentStep5 from './AssessmentStep5';
import AssessmentStep6 from './AssessmentStep6';
import AssessmentStep7 from './AssessmentStep7';
import AssessmentStep8 from './AssessmentStep8';
import AssessmentStep9 from './AssessmentStep9';
import SucccessModal from './SucccessModal';
import { lca_steps } from '../../../data';

//create mui styled components
const StyledBackButton = styled(Button)(({ theme }) => ({
  bgcolor: 'none',
  color: '#000',
  textTransform: 'capitalize',
  px: theme.spacing(2),
  '&:hover': {
    bgcolor: 'none',
  },
}));

const StyledMainText = styled(Typography)(({ theme }) => ({
  color: '#0E1090',
  fontSize: '24px',
  fontWeight: '700',
  marginTop: '16px',
  marginBottom: '25px',
}));

const LifeCycleStartAssessment = () => {
  const navigate = useNavigate();
  const params = useParams();
  const productID = params?.id;
  const [searchParams] = useSearchParams();
  const queryParamValue = searchParams.get('set');
  const { t, i18n} = useTranslation();
  const currentLanguage = i18n.language;
  const lcaInfo = JSON.parse(localStorage.getItem('createLCAInfo'));
  const editDraftlcaInfo = JSON.parse(localStorage.getItem('editDraftLcaInfo'));
  const [activeStep, setActiveStep] = useState(0);
  const [productInfo, setProductInfo] = useState(null);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let page = currentPage;
  const [productCount, setProductCount] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [stepCompleted, setStepCompleted] = useState([]);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [stepInfo, setStepInfo] = useState({});
  const [garmentCode, setGarmentCode] = useState();
  // const [isPrevStepInfo, setIsPrevStepInfo] = useState(false);
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);

  useEffect(() => {
    if (queryParamValue) {
      const targetStep = parseInt(queryParamValue - 1);
      setActiveStep(targetStep);
      const completedSteps = Array.from({ length: targetStep + 1 }, (_, i) => i);
      setStepCompleted(completedSteps);
    }
  }, [queryParamValue]);

  useEffect(() => {
    if (editDraftlcaInfo) {
      setStepInfo(editDraftlcaInfo);
    }
  }, []);

  useEffect(() => {
    if (productID) {
      fetchInitialProductData(page);
      fetchSingleProduct();
    }
  }, [productID, page, currentPage]);

  const fetchInitialProductData = async (page) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.product}?lca=false&page=${page}&limit=10&productId=${productID}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response?.data?.status) {
        setProducts(response?.data?.data?.products);
        setProductCount(response?.data?.data?.totalRecords);
      }
    } catch (error) {
      console.error('Error fetching product:', error);
    }
  };

  const fetchSingleProduct = async () => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.product}/${productID}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response?.data) {
        setProductInfo(response?.data?.data);
        setGarmentCode(response?.data?.data?.qrId)
      }
    } catch (error) {
      console.error('Error fetching product:', error);
    }
  };

  const handleNext = () => {
    setStepCompleted((prev) => [...prev, activeStep]);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStepClick = (index) => {
    if (stepCompleted.includes(index) || index === activeStep) {
      setProductInfo(null);
      setActiveStep(index);
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const handleSubmit = async (data, isDraft = false) => {
    let obj = {
      set: activeStep + 1,
    };
    
    if (activeStep === 0) {
      obj.garment = {
        type: data?.garment?.type,
        composition: data?.garment?.composition?.[currentLanguage],
        title: data?.garment?.title,
        weight: data?.garment?.weight,
      };
    } else {
      obj = { ...obj, ...data };
    }
    
    setStepInfo((prevStepInfo) => ({
      ...prevStepInfo,
      [activeStep]: data,
    }));
    try {
      if (!checkOnlineStatus()) return;
      const response = await httpService.patch(`${RestUrlsConstants.LCA}/${editDraftlcaInfo?.id ? editDraftlcaInfo?.id : lcaInfo?._id}`, obj, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },  
      });
      if (response?.data?.status) {
        fetchInitialProductData(page);
        setSnackBar({ open: true, message: response?.data?.message });
        if (isDraft) {
          navigate(PATH.LIFECYCLEASSESSMENT);
          setStepInfo({});
          // setIsPrevStepInfo(true);
        } else {
          // setIsPrevStepInfo(true);
          setStepInfo({ ...stepInfo, ...data });
          handleNext();
        }
      }
    } catch (error) {
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const totalPages = Math.ceil(productCount / 10);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const manageBackBtn = () => {
    if (editDraftlcaInfo?.id) {
      localStorage.removeItem('editDraftLcaInfo');
      navigate(PATH.EDITDRAFT);
      // setIsPrevStepInfo(false);
      setStepInfo({});
    } else {
      navigate(PATH.STARTCALCULATION);
    }
  };
  
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <AssessmentStep1 productInfo={stepInfo?.garment  || productInfo} handleSubmit={handleSubmit} garmentCode={garmentCode}/>;
      case 1:
        return (
          <AssessmentStep2
            products={products}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            handleSubmit={handleSubmit}
            totalPages={totalPages}
            handleChange={handleChange}
            productCount={productCount}
            page={page}
            productID={productID}
            handleNext={() => {
              setStepCompleted((prev) => [...prev, activeStep]);
              setActiveStep(2);
            }}
            data={stepInfo}
          />
        );
      case 2:
        return <AssessmentStep3 handleSubmit={handleSubmit} data={stepInfo} />;
      case 3:
        return <AssessmentStep4 handleSubmit={handleSubmit} data={stepInfo} />;
      case 4:
        return <AssessmentStep5 handleSubmit={handleSubmit} data={stepInfo} />;
      case 5:
        return <AssessmentStep6 handleSubmit={handleSubmit} data={stepInfo} />;
      case 6:
        return <AssessmentStep7 handleSubmit={handleSubmit} data={stepInfo} />;
      case 7:
        return <AssessmentStep8 handleSubmit={handleSubmit} data={stepInfo} />;
      case 8:
        return <AssessmentStep9 handleSubmit={handleSubmit} data={stepInfo} />;
      default:
        return <SucccessModal />;
    }
  };

  return (
    <Box mx={2}>
      <StyledBackButton startIcon={<IoMdArrowBack />} onClick={manageBackBtn}>
        {t('back')}
      </StyledBackButton>

      <StyledMainText>Start Assessment</StyledMainText>

      <Card sx={{ width: '100%', py: 1, borderRadius: '20px' }}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%', justifyContent: 'space-between', cursor: 'pointer' }}>
          {lca_steps.map((label, index) => (
            <Step key={label} completed={stepCompleted.includes(index)} onClick={() => handleStepClick(index)} sx={{ flex: 1 }}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Card>

      <Box>{renderStepContent(activeStep)}</Box>

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Box>
  );
};
export default LifeCycleStartAssessment;
