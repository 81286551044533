import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { Chip, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { InfoText, SquareChips, StyledButton } from '../../utils/LCAStep';
import { Box, Input, InputAdornment, Stack, useTheme } from '@mui/material';
import CommonSelect from '../../utils/CommonSelect';
import info from '../../../images/Vector.png';
import AssesmentFooter from '../../utils/AssesmentFooter';
import { lca_weight_field_validation_regex, packaginType } from '../../../data';
import CommonInput from '../../utils/CommonInput';

export const CommonInfoWrapper = (infoMsz) => {
  return (
    <Stack direction={'row'} spacing={1} alignItems={'flex-start'} sx={{ marginTop: '10px' }}>
      <img width={12} height={12} src={info} alt="info-icon" />
      <InfoText>{infoMsz}</InfoText>
    </Stack>
  );
};

export const CommonSquareChipsWrapper = (isChipClick = false, data, handleDelete, handleClick) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack
        direction="row"
        flexWrap="wrap"
        spacing={1}
        sx={{
          borderBottom: '2px solid #808080',
          width: '100%',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
      >
        {data?.map(({ label, selected }, i) => {
         return label ? (
          <SquareChips key={i} label={label} variant="outlined" customColor={selected ? '#0E1090' : ''} onDelete={() => handleDelete(i)} onClick={isChipClick ? undefined : () => handleClick(i)} />
        ): null;
        })}
      </Stack>
    </Box>
  );
};

const AssessmentStep4 = ({ handleSubmit, data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [chipsData, setChipsData] = useState([]);
  const [selectedChipIndex, setSelectedChipIndex] = useState(null);
  const [newChipName, setNewChipName] = useState('');

  useEffect(() => {
    if (data && data?.packaging?.length > 0) {
      const lastChipIndex = data.packaging.length - 1;
      const updatedChips = data?.packaging?.map((item, index) => ({
        [item.name]: {
          type: item.type || '',
          quantity: item.quantity || '',
          selected: index === lastChipIndex,
        },
      }));
      setChipsData(updatedChips);
      setSelectedChipIndex(lastChipIndex);
    }
  }, [data]);

  const onNext = (isDraft = false) => {
    if (chipsData.length > 0) {
      if (getCompletedChips().length > 0) {
        handleSubmit({ packaging: getCompletedChips() }, isDraft);
        setSelectedChipIndex(null);
      } else {
        setSnackBar({ open: true, message: 'Please select atleast one method name' });
      }
    } else {
      handleSubmit({ packaging: getCompletedChips() }, isDraft);
      setSelectedChipIndex(null);
    }
  };

  const handleClick = (index) => {
    const selectedChip = chipsData[index];
    if (selectedChip) {
      const updatedChips = chipsData.map((chip, i) => {
        const chipLabel = Object.keys(chip)[0];
        return {
          [chipLabel]: { ...chip[chipLabel], selected: i === index },
        };
      });

      setChipsData(updatedChips);
      setSelectedChipIndex(index);
    }
  };

  const onAddChip = () => {
    if (!newChipName.trim()) {
      setSnackBar({ open: true, message: 'Method name cannot be empty.' });
      return;
    }
    if (chipsData.some((chip) => chip[newChipName.trim()])) {
      setSnackBar({ open: true, message: 'Same method name already exists.' });
      return;
    }
    if (chipsData.length >= 2) {
      setSnackBar({ open: true, message: 'You can only add up to two method names.' });
      return;
    }
    const newChip = {
      [newChipName.trim()]: {
        type: '',
        quantity: '',
        selected: true,
      },
    };

    setChipsData((prevChips) => [
      ...prevChips.map((chip) => {
        const chipLabel = Object.keys(chip)[0];
        return { [chipLabel]: { ...chip[chipLabel], selected: false } };
      }),
      newChip,
    ]);

    setNewChipName('');
    setSelectedChipIndex(chipsData.length);
  };

  const handleDelete = (index) => {
    const updatedChips = chipsData.filter((_, i) => i !== index);
    let newSelectedChipIndex = null;

    if (updatedChips.length > 0) {
      if (index === chipsData.length - 1) {
        newSelectedChipIndex = updatedChips.length - 1;
      } else {
        newSelectedChipIndex = index;
      }
      const chipLabel = Object.keys(updatedChips[newSelectedChipIndex])[0];
      updatedChips[newSelectedChipIndex] = {
        [chipLabel]: { ...updatedChips[newSelectedChipIndex][chipLabel], selected: true },
      };
    }

    setChipsData(updatedChips);
    setSelectedChipIndex(newSelectedChipIndex);
  };

  const isChipCompleted = (chipIndex, label) => {
    let status;
    chipsData.forEach((item, index) => {
      if (index === chipIndex) {
        const { type, quantity } = item[label];
        if (type && quantity) {
          status = true;
        } else status = false;
      }
    });
    return status;
  };

  const getFieldValues = () => {
    if (selectedChipIndex !== null && chipsData[selectedChipIndex]) {
      const selectedChipObj = chipsData[selectedChipIndex];
      const chipLabel = Object.keys(selectedChipObj)[0];
      return selectedChipObj[chipLabel];
    }
    return {};
  };

  const getCompletedChips = () => {
    const result = chipsData
      .filter((item, index) => {
        const chipLabel = Object.keys(item)[0];
        return isChipCompleted(index, chipLabel);
      })
      .map((item) => {
        const chipLabel = Object.keys(item)[0];
        const { selected, ...chipData } = item[chipLabel];
        return {
          name: chipLabel,
          ...chipData,
        };
      });
    return result;
  };

  const updateFieldValues = (fieldName, newValue) => {
    if (selectedChipIndex != null) {
      const selectedChipObj = chipsData[selectedChipIndex];
      const chipLabel = Object.keys(selectedChipObj)[0];
      setChipsData((prevData) => {
        const updatedData = [...prevData];
        if (updatedData[selectedChipIndex] && updatedData[selectedChipIndex][chipLabel]) {
          updatedData[selectedChipIndex] = {
            ...updatedData[selectedChipIndex],
            [chipLabel]: {
              ...updatedData[selectedChipIndex][chipLabel],
              [fieldName]: newValue,
            },
          };
        } else {
          console.log(`Invalid selectedChipIndex, chipLabel, or field name: selectedChipIndex ${selectedChipIndex}, chipLabel ${chipLabel}, Field ${fieldName}`);
        }

        return updatedData;
      });
    } else {
      setSnackBar({ open: true, message: 'Please add atleast one method name' });
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const isDisabled = useMemo(() => {
    const completedChips = getCompletedChips();
    const areAllChipsComplete = completedChips.length === chipsData.length;
    const currentFieldValues = getFieldValues();
    const areCurrentFieldsValid = currentFieldValues?.type && currentFieldValues?.quantity;
    return !areAllChipsComplete || !areCurrentFieldsValid;
  }, [getCompletedChips, chipsData.length, getFieldValues]);

  return (
    <Box>
      <Box mt={5}>
        <Card sx={{ borderRadius: '20px', px: isMobile ? 1 : 4 }}>
          <Box my={3}>
            <Typography textTransform="uppercase" fontWeight="700" variant="h5" color="#0E1090">
              {t('packaging')}
            </Typography>

            <Box my={3}>
              <Typography sx={{ cursor: 'pointer' }} fontWeight="700" pb={4}>
                {t('primary_packaging_used')}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Input
                id="input-with-icon-textfield"
                placeholder="Enter Method Name"
                sx={{ width: '100%', paddingBottom: 2 }}
                variant="standard"
                value={newChipName}
                onChange={(e) => {
                  const value = e.target.value;
                  const isValid = /^[a-zA-Z\s]*$/.test(value);
                  if (isValid && value.length <= 250) {
                    setNewChipName(value);
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <StyledButton variant="contained" isBgColor="#0E1090" onClick={onAddChip}>
                      {t('ADD')}
                    </StyledButton>
                  </InputAdornment>
                }
              />
            </Box>

            {chipsData.length > 0 && (
              <Box mt={2}>
                {chipsData.map((chip, index) => {
                  const label = Object.keys(chip)[0];
                  return (
                    <Chip
                      key={index}
                      label={label}
                      onDelete={() => handleDelete(index)}
                      onClick={() => handleClick(index)}
                      color={chip[label].selected ? 'primary' : 'default'}
                      sx={{ marginRight: 1, marginBottom: 1, cursor: 'pointer' }}
                    />
                  );
                })}
              </Box>
            )}

            <Box>
              <Stack mt={2}>
                <CommonSelect
                  label={
                    <span style={{ cursor: 'pointer' }}>
                      {t('Type')}
                      <span style={{ color: 'red' }}>*</span>
                      <Tooltip title={'Please select the materials used for primary packaging'} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  options={packaginType}
                  value={getFieldValues()?.type || ''}
                  handleChange={(e) => updateFieldValues('type', e.target.value)}
                />
                <CommonInput
                  label={
                    <span>
                      {' '}
                      {t('weight_kg')}
                      <span style={{ color: 'red' }}>*</span>
                      <Tooltip title={'Please indicate the weight (in kg) of each packaging material used for one piece of garment'} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  type="text"
                  isSpacing
                  variant="standard"
                  autoComplete={false}
                  value={getFieldValues()?.quantity || ''}
                  handleChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || lca_weight_field_validation_regex.test(value)) {
                      if (value !== '0') {
                        updateFieldValues('quantity', value);
                      }
                    }
                  }}
                />
              </Stack>
            </Box>
          </Box>
        </Card>
      </Box>

      <AssesmentFooter isDraftDisabled={isDisabled} isNextBtnDisabled={isDisabled} onNext={onNext} Typography1="Answer the category" Typography2="Continue to the next question" />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Box>
  );
};

export default AssessmentStep4;
